import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BookDetail } from '../../types/ebooks/ebooks';
import { fetchBook, updateEbook } from '../../services/ebooks';
import uploadIcon from '../../assets/upload.png'; // Ensure you have an upload icon in your assets
import { FaArrowLeft } from 'react-icons/fa';  // Import arrow left icon

const EditEbook: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [book, setBook] = useState<BookDetail | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const navigate = useNavigate();

  useEffect(() => {
    const loadBook = async () => {
      if (id) {
        const data = await fetchBook(parseInt(id));
        setBook(data || null);
      }
    };
    loadBook();
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (!book) return;
    const { name, type, value } = e.target;
    if (type === 'file') {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        setBook(prevBook => ({
          ...prevBook!,
          [name]: target.files![0],
        }));
      }
    } else {
      setBook(prevBook => ({
        ...prevBook!,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!book?.title) newErrors.title = 'Title is required';
    if (!book?.description) newErrors.description = 'Description is required';
    if (book?.pdfUrl && typeof book.pdfUrl !== 'string' && (book.pdfUrl as File).type !== 'application/pdf') {
      newErrors.pdfUrl = 'Only PDF files are supported';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (book && validateForm()) {
      const updatedBook = {
        ...book,
        pdfUrl: typeof book.pdfUrl === 'string' ? book.pdfUrl : URL.createObjectURL(book.pdfUrl as File),
        cover: typeof book.cover === 'string' ? book.cover : URL.createObjectURL(book.cover as File),
      };
      // await updateEbook(updatedBook);
      navigate('/ebooks/manage-ebook');
    }
  };

  if (!book) return <div>Loading...</div>;
  const handleBack = () => {
    navigate('/ebooks/manage-ebook');
  };
  return (
    <div className="max-w-xl mx-auto mt-10 p-8 bg-white rounded-xl shadow-lg">
      <button onClick={handleBack} className="text-blue-500 text-lg rounded-full p-2 inline-flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 ease-in-out">
        <FaArrowLeft className="text-xl" /> {/* Icon for back navigation */}
      </button>
      <h2 className="text-3xl font-semibold text-center mb-6">Edit Ebook</h2>
      <p className="text-center text-gray-600 mb-8">Update the details of your ebook.</p>
      <form className="space-y-6">
        {/* Author Input */}
        <div>
          <label htmlFor="author" className="block text-lg font-bold text-black">Author Name</label>
          <input
            type="text"
            id="author"
            name="author"
            value={book?.author}
            onChange={handleChange}
            placeholder="Enter author name"
            className={`mt-1 block w-full border ${errors.author ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm p-3`}
          />
          {errors.author && <p className="text-red-500 text-sm mt-1">{errors.author}</p>}
        </div>

        {/* Title Input */}
        <div>
          <label htmlFor="title" className="block text-lg font-bold text-black">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={book?.title}
            onChange={handleChange}
            placeholder="Enter ebook title"
            className={`mt-1 block w-full border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm p-3`}
          />
          {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
        </div>

        {/* Description Input */}
        <div>
          <label htmlFor="description" className="block text-lg font-bold text-black">Description</label>
          <textarea
            id="description"
            name="description"
            value={book?.description}
            onChange={handleChange}
            placeholder="Provide a brief description"
            className={`mt-1 block w-full border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm p-3`}
          />
          {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
        </div>

        {/* Genre Dropdown */}
        <div>
          <label htmlFor="genre" className="block text-lg font-bold text-black">Genre</label>
          <select
            id="genre"
            name="genre"
            value={book?.genre}
            onChange={handleChange}
            className={`mt-1 block w-full border ${errors.genre ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm p-3`}
          >
            <option value="">Select a genre</option>
            <option value="fiction">Fiction</option>
            <option value="nonfiction">Non-fiction</option>
            <option value="romance">Romance</option>
            <option value="mystery">Mystery</option>
            <option value="fantasy">Fantasy</option>
            <option value="science fiction">Science Fiction</option>
            <option value="educational">Educational</option>
          </select>
          {errors.genre && <p className="text-red-500 text-sm mt-1">{errors.genre}</p>}
        </div>

        

        {/* PDF and Thumbnail Inputs */}
        {typeof book?.pdfUrl === 'string' && (
          <div>
            <label className="block text-lg font-bold text-black">Current PDF</label>
            <a href={book.pdfUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
              View Current PDF
            </a>
          </div>
        )}

        <div>
          <label htmlFor="pdfUrl" className="block text-lg font-bold text-black">Upload New PDF</label>
          <div className="flex items-center space-x-3">
            <input
              type="file"
              id="pdfUrl"
              name="pdfUrl"
              accept="application/pdf"
              onChange={handleChange}
              className="block w-full text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
            <label htmlFor="pdfUrl" className="cursor-pointer">
              <img src={uploadIcon} alt="Upload PDF" className="inline-block h-6 w-6"/>
            </label>
          </div>
          {errors.pdfUrl && <p className="text-red-500 text-sm mt-1">{errors.pdfUrl}</p>}
        </div>

        {typeof book?.cover === 'string' && (
          <div>
            <label className="block text-lg font-bold text-black">Current Thumbnail</label>
            <img src={book.cover} alt={book.title} className="h-40 mt-2" />
          </div>
        )}

        <div>
          <label htmlFor="cover" className="block text-lg font-bold text-black">Upload New Thumbnail</label>
          <div className="flex items-center space-x-3">
            <input
              type="file"
              id="cover"
              name="cover"
              accept="image/png, image/svg+xml"
              onChange={handleChange}
              className="block w-full text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
            />
            <label htmlFor="cover" className="cursor-pointer">
              <img src={uploadIcon} alt="Upload Thumbnail" className="inline-block h-6 w-6"/>
            </label>
          </div>
          {errors.cover && <p className="text-red-500 text-sm mt-1">{errors.cover}</p>}
        </div>

        {/* Save Button */}
        <div className="flex justify-center">
          <button
            type="button"
            onClick={handleSave}
            className="bg-blue-500 text-white py-4 px-6 text-sm rounded-md text-xl hover:bg-blue-600 transition"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEbook;
