import React from 'react';

const Explore: React.FC = () => {
  return (
    <div>
      <h1>Explore Page</h1>
      <p>Discover new content on the Explore page.</p>
    </div>
  );
};

export default Explore;
