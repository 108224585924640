import React from 'react';
import { NavLink } from 'react-router-dom';
import { IconType } from 'react-icons';
import { NavLinkProps } from '../../types/layouts';



const CustomNavLink: React.FC<NavLinkProps> = ({ to, icon: Icon, label, isCollapsed }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center p-4 h-12 hover:bg-gray-200 transition-colors duration-1000 ${
          isActive ? 'bg-gray-200' : ''
        }`
      }
    >
      <Icon className="mr-4" />
      <div
        className={`w-0 opacity-0 group-hover:block text-nowrap items-center group-hover:opacity-100 transition-opacity duration-100 ${
          isCollapsed ? 'hidden' : 'block'
        }`}
      >
        {label}
      </div>
    </NavLink>
  );
};

export default CustomNavLink;
