import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import { Playlist } from "../types/domain";
import { useNavigate } from "react-router-dom";
import { FaList, FaListOl, FaListUl } from "react-icons/fa";
import { getYoutubeThumbnail } from "../utils/youtube";

interface PlaylistThumbnailProps {
  playlist: Playlist;
}

export default function PlaylistThumbnail({
  playlist,
}: PlaylistThumbnailProps) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/videos/collection/${playlist.id}`)}
      sx={{
        cursor: "pointer",
        transition: "transform 0.2s",
        ":hover": {
          transform: "scale(1.05)",
        },
        ":active": {
          transform: "scale(1.02)",
        },
        "@media (min-width: 600px)": {
          width: "calc(50% - 16px)",
        },
        "@media (min-width: 960px)": {
          width: "calc(33.33% - 16px)",
        },
        "@media (min-width: 1280px)": {
          width: "calc(25% - 16px)",
        },
      }}
    >
      <div className="flex justify-center items-center">
        <div className="absolute ">
          <Typography variant="h1">
            {/* {playlist.videos.length} video
            {playlist.videos. != 1 ? "s" : ""} */}
          </Typography>
        </div>
        <CardMedia
          component="img"
          image={getYoutubeThumbnail(playlist.playlistThumbnail)}
          alt={"could not load thumbnail"}
          sx={{
            opacity: 0.4,
          }}
        />
      </div>
      <CardContent className="flex items-center">
        <FaList />
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            marginRight: 2,
            marginLeft: 2,
          }}
        />
        <Typography variant="body1">{playlist.name}</Typography>
      </CardContent>
    </Card>
  );
}
