import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { Routes, Route } from 'react-router-dom';
import Home from '../../pages/Home';
import Explore from '../../pages/Explore';
import User from '../../pages/User';
import YourChannel from '../../pages/YourChannel';
import History from '../../pages/History';
import Playlists from '../../pages/Playlists';
import YourVideos from '../../pages/YourVideos';
import WatchLater from '../../pages/WatchLater';
import LikedVideos from '../../pages/LikedVideos';
import IndividualPlaylist from '../../components/IndividualPlaylist';
import { VideoLayoutProps } from '../../types/layouts';
import { Watch } from '../../pages/Watch';
import { PlaylistProvider } from '../../components/playlistContext';
import { WatchLaterProvider } from '../../components/watchLaterContext';
import { WatchHistoryProvider } from '../../components/watchHistoryContext';
import VideoDetails from '../../components/VideoDetails';
import Analytics from '../../pages/Analytics';
import ChannelPage from '../../pages/ChannelPage';
import PageNotFound from '../../pages/PageNotFound';
import PlaylistDetail from '../../pages/PlaylistDetail';
import CollectionDetail from '../../pages/CollectionDetail';
import PlaylistPage from '../../pages/PlaylistPage';
import AppBar from '../GeneralAppBar/AppBar';
import { searchType } from '../../services/types';

const VideoLayout: React.FC<VideoLayoutProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleSearch = async (query: string) => {
    const mediaType = window.location.pathname.startsWith('/ebooks') ? 'Ebook' : 'Video';
    try {
      const results = await searchType(query, mediaType);
      // Handle the response, update state or display the search results
      console.log(results);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  return (
    <PlaylistProvider>
      <WatchLaterProvider>
        <WatchHistoryProvider>
          <div className="flex flex-col h-screen">
            <div className="container mx-auto px-4 py-6">
              <AppBar
                toggleSidebar={toggleSidebar}
                setSearchQuery={(query) => {
                  setSearchQuery(query);
                  handleSearch(query);
                }}
                searchQuery={searchQuery}
              />
            </div>

            {/* Sidebar */}
            <Sidebar isCollapsed={isCollapsed} />

            {/* Main content area */}
            <div className={`p-6 mt-0 flex-grow transition-all duration-300 ${isCollapsed ? 'ml-16' : 'ml-32'}`}>
              <Routes>
                <Route path="/home" element={<Home searchQuery={searchQuery} />} />
                <Route path="/explore" element={<Explore />} />
                <Route path="/user" element={<User />} />
                <Route path="/your-channel" element={<YourChannel />} />
                <Route path="/history" element={<History searchQuery={searchQuery} />} />
                <Route path="/playlists" element={<Playlists searchQuery={searchQuery} />} />
                <Route path="/collection/:playlistId" element={<CollectionDetail />} />
                <Route path="/playlist/:playlistId" element={<PlaylistDetail />} />
                <Route path="/your-videos/*" element={<YourVideos />} />
                <Route path="/watch-later" element={<WatchLater searchQuery={searchQuery} />} />
                <Route path="/liked-videos" element={<LikedVideos />} />
                <Route path="/your-playlist/:name" element={<IndividualPlaylist />} />
                <Route path="/see/:id" element={<VideoDetails />} />
                <Route path="/watch/:id" element={<Watch />} />
                <Route path="/channel/:id" element={<ChannelPage />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/" element={children} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </div>
        </WatchHistoryProvider>
      </WatchLaterProvider>
    </PlaylistProvider>
  );
};

export default VideoLayout;
