import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Video } from '../types/forms/video';
import VideoForm from '../components/forms/addVideo/VideoForm';
import VideoList from '../components/uploadVideo/VideoList'; // Correct import path
import { FormProvider } from '../components/forms/addVideo/FormContext';
import {ChannelProvider} from '../contexts/ChannelContext'; // Correct import path

const YourVideos: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);

  const handleAddVideo = (newVideo: Video) => {
    setVideos([...videos, newVideo]);
  };

  const handleUpdateVideo = (updatedVideo: Video) => {
    const updatedVideos = videos.map(video =>
      video.id === updatedVideo.id ? updatedVideo : video
    );
    setVideos(updatedVideos);
  };

  const handleDeleteVideo = (id: number) => {
    setVideos(videos.filter(video => video.id !== id));
  };

  return (
    <FormProvider>
      <ChannelProvider>
      <Routes>
        <Route path="/" element={<VideoList videos={videos} onDelete={handleDeleteVideo} />} />
        <Route path="/form/:id?" element={<VideoForm videos={videos} onAdd={handleAddVideo} onUpdate={handleUpdateVideo} />} />
      </Routes>
      </ChannelProvider>
    </FormProvider>
  );
};

export default YourVideos;