import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Video, Person, Segment, FormContextProps, VideoFormProps } from '../../../types/forms/video';
import { FormContext } from './FormContext';
import Details from './Details';
import VideoElements from './VideoElements';
import Checks from './Checks';
import ProgressIndicator from './ProgressIndicator';
import { uploadVideo,uploadSegment, updateVideo,updateMedia } from '../../../services/uploadVideo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {fetchVideoDataByID,fetchTranscriptsByID} from '../../../services/uploadVideo'
import { title } from 'process';
import {useChannel} from  '../../../contexts/ChannelContext'


const VideoForm: React.FC<VideoFormProps> = ({ videos, onAdd, onUpdate }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const formContext = useContext<FormContextProps | undefined>(FormContext);

  const [currentStep, setCurrentStep] = useState(1);
  const [segments, setSegments] = useState<Segment[]>([]); // Initialize the segments state
  const [monumentImage, setMonumentImage] = useState<File | null>(null);
  const [cityImage, setCityImage] = useState<File | null>(null);


  const canMoveToStep = (step: number): boolean => {
    if (step === 2) {
      return !!formData.media_details.title;
    } else if (step === 3) {
      return !!formData.media_details.title && !!formData.videoURL;
    }
    return true;
  };

  const handleStepChange = (step: number) => {
    if (canMoveToStep(step)) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    if (formContext) {
      const { updateFormData, resetFormData } = formContext;
      
  
      if (id) {
        // Fetch the video data asynchronously
        fetchVideoDataByID(id)
          .then((x) => {
            if (x) {
              console.log("vids",x);
              console.log('coollec name',x.collection_id);            
              updateFormData({
                media_details: {
                  ...formData.media_details, // Spread the existing media details to avoid overwriting other fields
                  title: x.media_details.title, // Set the title from the video data
                  description: x.media_details.description,
                  originalLanguage: x.media_details.originalLanguage,
                  channelID: x.media_details.channelID,


                },
                fullTranscript: x.fullTranscript,
                location: x.city ? {
                  type: "City", // Add type property
                  name: x.city.city_name, // Use name for city_name
                  city_name: x.city.city_name,
                  city_image: x.city.city_image,
                } : {
                  type: "Monument", // Add type property
                  name: x.monument.monument_name, // Use name for monument_name
                  monument_name: x.monument.monument_name,
                  monument_image: x.monument.monument_image,
                  city_name: x.monument.city.city_name ,
                  city_image: x.monument.city.city_image,

                },
                transcript: {
                  transcription: x.transcripts[0]?.transcription,
                  transcriptionLanguage:x.transcripts[0]?.transcriptionLanguage,

                },
                        
                acknowledgment: x.media_details.acknowledgement,
                category: x.media_details.categoryID,
                visibility: x.visibility === "1" ? "public" : x.visibility === "2" ? "unlisted" : "private", 
                videoURL: x.videoURL,
                important_persons: x.important_persons,
                topics: x.topics,
                interviewDate: x.interviewDate,
                collectionName:x.collection_id,
                // Assuming 'x.participants' is an array of Person objects
                interviewees: x.participants
                .filter((p: Person) => p.role === 2)  // Filter interviewees by role
                .map((p: Person) => ({
                  firstName: p.firstName,
                  lastName: p.lastName,
                  phoneNumber: '',  // Handle optional fields
                  email: '',
                  picture: '',
                  role: p.role,
                })),

                interviewers: x.participants
                .filter((p: Person) => p.role === 1)  // Filter interviewers by role
                .map((p: Person) => ({
                  firstName: p.firstName,
                  lastName: p.lastName,
                  phoneNumber: '',  // Handle optional fields
                  email: '',
                  picture: '',
                  role: p.role,
                })),

              });
            }

          })
          .catch((error) => {
            console.error("Failed to fetch video data:", error);
          });
  
        // Fetch the transcript data asynchronously
        fetchTranscriptsByID(id)
        .then((transcriptData) => {
          if (transcriptData) {
            console.log("trs",transcriptData)
            // Map the API response to match the Segment structure in the Video interface
            const segments = transcriptData.combinedSegmentTranscripts.map((item:any) => ({
              id: item.segment.id,
              segmentNumber: item.segment.segmentNumber,
              startTime: item.segment.startTime,
              endTime: item.segment.endTime,
    
              description: item.segment.description,
              transcripts: item.transcripts.map((transcript: { id: any; title: any; content: any; transcriptDate: any; transcription: any; transcriptionLanguage: any; videoID: any; videoSegmentID: any; transcriberID: any; }) => ({
                id: transcript.id,
                title: transcript.title,
                
                transcript: transcript.transcription,
              }))
            }));

            updateFormData(({
              segments, // Assign the processed segments data
            }));
          }
        })
        .catch((error) => {
          console.error("Failed to fetch transcript data:", error);
        });

      } else {
        resetFormData();
      }
    }
  }, [id]);
  
  

  if (!formContext) {
    return <div>Error: Form context is not available.</div>;
  }

  const { formData, updateFormData } = formContext;
  
  const updateNestedObject = (object: any, path: string, value: any) => {
    const keys = path.split('.');
    let tempObject = { ...object };
  
    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        acc[key] = value;
      } else {
        acc[key] = acc[key] || {};
      }
      return acc[key];
    }, tempObject);
  
    return tempObject;
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log("n",name,'v',value)
    const updatedFormData = updateNestedObject(formData, name, value);
    console.log("updatedFormData",value);
    updateFormData(updatedFormData);
  };
  

  const handleNext = () => {
    if (canMoveToStep(currentStep + 1)) {
      setCurrentStep(prevStep => prevStep + 1);
    }

  };

  const handleBack = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    console.log('Data to be sent:', JSON.stringify(formData, null, 2));
  
    try {
      if(id){
        console.log("id",id)

        const response = await updateVideo(id,formData);
        const mediaResponse = await updateMedia(id,formData);
      }
      else{
      const response = await uploadVideo(formData);

      console.log('Submitted data:', formData);
      }
      if (id) {
        onUpdate(formData);
      } else {
        onAdd({ ...formData, id: Date.now() }); 
      }
      /*if (response.id && formData.segments.length > 0) {
        console.log('Uploading segments...');
        await uploadSegment(response.id, formData.segments);
        console.log('Segments uploaded successfully');
      }*/
      toast.success('Video submitted successfully!');
      navigate('/videos/your-videos');
    } catch (error:any) {
      if (axios.isAxiosError(error)) {
        toast.error(`Failed to upload video: ${error.response?.data || error.message}`);
        console.error('Failed to upload video:', error.response?.data || error.message);
      } else {
        toast.error('Failed to upload video. Please try again later.');
        console.error('Failed to upload video:', error);
      }
    }
};


  const handleSavePerson = (type: 'interviewee' | 'interviewer' | 'participant', firstName: string, lastName: string, picture?: File, phoneNumber?: string, email?: string, index?: number) => {
    const newPerson: Person = { firstName, lastName, phoneNumber: phoneNumber ?? '', role: type === 'interviewee' ? 2 : 1 };

    if (type === 'interviewee') {
      const updatedInterviewees = [...formData.interviewees];
      if (index !== undefined) {
        updatedInterviewees[index] = newPerson;
      } else {
        updatedInterviewees.push(newPerson);
      }
      updateFormData({ interviewees: updatedInterviewees });
    } else if (type === 'interviewer') {
      const updatedInterviewers = [...formData.interviewers];
      if (index !== undefined) {
        updatedInterviewers[index] = newPerson;
      } else {
        updatedInterviewers.push(newPerson);
      }
      updateFormData({ interviewers: updatedInterviewers });
    } 
  };

  return (
    <div className="form-container">
      <ProgressIndicator currentStep={currentStep} onStepChange={handleStepChange} canMoveToStep={canMoveToStep} />
      <form onSubmit={handleSubmit}>
        {currentStep === 1 && (
          <Details formData={formData} handleChange={handleChange} handleNext={handleNext} />
        )}
        {currentStep === 2 && (
          <VideoElements
            formData={formData}
            handleChange={handleChange}
            handleNext={handleNext}
            handleBack={handleBack}
            updateFormData={updateFormData}
          />
        )}
        {currentStep === 3 && (
          <Checks
            formData={formData}
            handleChange={handleChange}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            handleSavePerson={handleSavePerson}
          />
        )}
      </form>
      <ToastContainer />
    </div>
  );
};


export default VideoForm;