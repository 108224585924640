import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserPlaylists } from '../services/playlistService';
import { PlaylistWithSelectedVideo as Playlist } from '../types/domain';

const Playlists: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [localSearchQuery, setLocalSearchQuery] = useState('');
  const navigate = useNavigate();

  const getYoutubeThumbnail = (url: string | null | undefined) => {
    if (!url) {
      return 'default-thumbnail-url.jpg'; // Fallback if no video URL is present
    }
  
    const videoId = url.split('v=')[1]?.split('&')[0]; // Safely extract video ID
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };
  
  useEffect(() => {
    const loadPlaylists = async () => {
      try {
        const fetchedPlaylists = await fetchUserPlaylists();
        setPlaylists(fetchedPlaylists.results); // Set only the results array to the playlists state
      } catch (error) {
        console.error('Failed to load playlists:', error);
      }
    };
    loadPlaylists();
  }, []);

  const handlePlaylistClick = (playlistId: string) => {
    navigate(`/videos/playlist/${playlistId}`);
  };
  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchQuery(e.target.value);
  };

  const filteredPlaylists = playlists.filter((playlist) =>
    playlist.name.toLowerCase().includes((searchQuery || localSearchQuery).toLowerCase())
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Playlists</h1>
      <div className="relative w-full mb-4">
        <input
          type="text"
          placeholder="Search playlists..."
          className="p-2 border border-gray-300 rounded-lg w-1/7"
          value={localSearchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredPlaylists.length > 0 ? (
          filteredPlaylists.map((playlist) => (
            <div
              key={playlist.id}
              className="relative p-4 bg-white rounded-lg shadow-lg hover:shadow-2xl transform transition duration-300 hover:scale-105 cursor-pointer"
              onClick={() => handlePlaylistClick(playlist.id)}  // Navigate to PlaylistDetail page
            >
          {playlist.playlistThumbnail && (
              <img
                src={getYoutubeThumbnail(playlist.playlistThumbnail)}  // Call function to get the thumbnail URL
                alt={playlist.name}
                className="w-full h-32 object-cover rounded-t-lg"
              />
            )}

              <div className="p-2">
                <h3 className="text-lg font-semibold text-primary">{playlist.name}</h3>
                <p className="text-sm text-gray-600">{playlist.videoCount || 0} videos</p>  {/* Use videoCount here */}
              </div>
            </div>
          ))
        ) : (
          <p>No playlists available</p>
        )}
      </div>
    </div>
  );
};

export default Playlists;
