import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { fetchPlaylistVideos } from '../services/playlistService';
import { Video } from '../types/domain';  // Assuming Video type is defined
import {getYoutubeThumbnail} from '../utils/helpers/getYoutubeThumbnail';  // Import the utility function
const PlaylistDetail: React.FC = () => {
  const { playlistId } = useParams<{ playlistId: string }>();
  const [videos, setVideos] = useState<Video[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("useEffect triggered for playlistId:", playlistId); // Add this log to check if it's triggered
  
    const loadPlaylistVideos = async () => {
      try {
        const fetchedVideos = await fetchPlaylistVideos(Number(playlistId));
        if (fetchedVideos && fetchedVideos.playlist_media) {
          setVideos(fetchedVideos.playlist_media.map((media: any) => media.video_details));
          console.log("test success", fetchedVideos); // Log the fetched data
        }
      } catch (error) {
        console.error('Failed to load videos for playlist:', error);
      }
    };
  
    loadPlaylistVideos();
  }, [playlistId]);
  

  const handleVideoClick = (id: string) => {
    // You can navigate to a video detail page or perform some action
      navigate(`/videos/watch/${id}`);
    };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Playlist Videos</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {videos.length > 0 ? (
          videos.map((video) => (
            <div
              key={video.id}
              className="relative p-4 bg-white rounded-lg shadow-lg hover:shadow-2xl transform transition duration-300 hover:scale-105 cursor-pointer"
              onClick={() => handleVideoClick(video.id)}  // Now passes the string ID
            >
              <img
                src={getYoutubeThumbnail(video.videoURL)}  // Ensure correct thumbnail
                alt={video.media_details.title}
                className="w-full h-32 object-cover rounded-t-lg"
              />
              <div className="p-2">
                <h3 className="text-lg font-semibold">{video.media_details.title}</h3>
                <p className="text-sm text-gray-600">{video.media_details.description}</p>
              </div>
            </div>
          ))
        ) : (
          <p>No videos in this playlist</p>
        )}
      </div>
    </div>
  );
};

export default PlaylistDetail;