import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { FilterState, QuantityInputProps, VideoFilterProps } from '../../types/forms/video';

const QuantityInput: React.FC<QuantityInputProps> = ({ value, onChange, min = 0, max = 300 }) => {
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };
  const handleDecrement = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };
  return (
    <div className="flex items-center">
      <button className="px-2 py-1 bg-gray-200 rounded-2xl" onClick={handleIncrement}>+</button>
      <input
        type="number"
        className="border border-gray-300 rounded-2xl px-2 py-1 mx-2 w-16"
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
      <button className="px-2 py-1 bg-gray-200 rounded-2xl" onClick={handleDecrement}>-</button>
    </div>
  );
};

const mockCategories = ['Category 1', 'Category 2', 'Category 3'];

const VideoFilter: React.FC<VideoFilterProps> = ({ filters, onFilterChange }) => {
  const [minViews, setMinViews] = useState(filters.minViews);
  const [maxViews, setMaxViews] = useState(filters.maxViews);

  useEffect(() => {
    setMinViews(filters.minViews);
    setMaxViews(filters.maxViews);
  }, [filters.minViews, filters.maxViews]);

  const handleSliderChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setMinViews(value[0]);
      setMaxViews(value[1]);
      onFilterChange('minViews', value[0]);
      onFilterChange('maxViews', value[1]);
    }
  };

  const handleMinInputChange = (value: number) => {
    setMinViews(value);
    onFilterChange('minViews', value);
  };

  const handleMaxInputChange = (value: number) => {
    setMaxViews(value);
    onFilterChange('maxViews', value);
  };

  return (
    <div className="flex justify-between items-center space-x-4 mb-4">
      <select
        className="border border-gray-300 rounded-2xl px-4 py-2"
        value={filters.type}
        onChange={(e) => onFilterChange('type', e.target.value)}
      >
        <option value="all">Media Type</option>
        <option value="Video">Video</option>
        <option value="Audio">Audio</option>
      </select>

      <div className="flex items-center space-x-2">
        <div className="flex flex-col items-center space-y-1">
          <span>Min Views</span>
          <input
            type="number"
            className="border border-gray-300 rounded-2xl px-2 py-1 w-16"
            value={minViews}
            onChange={(e) => handleMinInputChange(Number(e.target.value))}
          />
        </div>
        <Slider
          range
          min={0}
          max={100}
          value={[minViews, maxViews]}
          onChange={handleSliderChange}
          style={{ width: 200 }}
        />
        <div className="flex flex-col items-center space-y-1">
          <span>Max Views</span>
          <input
            type="number"
            className="border border-gray-300 rounded-2xl px-2 py-1 w-16"
            value={maxViews}
            onChange={(e) => handleMaxInputChange(Number(e.target.value))}
          />
        </div>
      </div>

      <select
        className="border border-gray-300 rounded-2xl px-4 py-2"
        value={filters.category}
        onChange={(e) => onFilterChange('category', e.target.value)}
      >
        <option value="all">All Categories</option>
        {mockCategories.map(category => (
          <option key={category} value={category}>{category}</option>
        ))}
      </select>
    </div>
  );
};

export default VideoFilter;