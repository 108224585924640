import React, { useEffect, useState } from "react";
import { FaEye, FaHeart } from "react-icons/fa";

interface CommentSectionProps {
  viewsToPass: number;
}

export default function Views({ viewsToPass }: CommentSectionProps) {
  const [views, setViews] = useState<number>(0);
  useEffect(() => {
    viewsToPass ? setViews(viewsToPass + 1) : setViews(0);
  }, [viewsToPass]);
  return <div> {views} Views </div>;
}
