import React, { useState } from 'react';
import { Routes, Route, useParams, useLocation } from 'react-router-dom';
import Sidebar from '../ebooks/Sidebar';
import AppBar from '../GeneralAppBar/AppBar';
import Trending from '../../pages/ebooks/Trending';
import Reading from '../../pages/ebooks/Reading';
import ToRead from '../../pages/ebooks/ToRead';
import Read from '../../pages/ebooks/Read';
import Notebook from '../../pages/ebooks/Notebook';
import PageNotFound from '../../pages/PageNotFound';
import Home from '../../pages/ebooks/Home';
import { Book } from '../../types/ebooks/ebooks';
import BookDetails from '../../components/ebooks/BookDetails';
import Reader from '../../pages/ebooks/Reader';
import Quiz from '../../pages/ebooks/Quiz';
import QuizHistory from '../../pages/ebooks/QuizHistory';
import AddEbookForm from '../../pages/ebooks/AddEbookForm';
import ManageEbook from '../../pages/ebooks/ManageEbook';
import EditEbook from '../../pages/ebooks/EditEbook';
import ManageQuiz from '../../pages/ebooks/ManageQuiz';
import ViewQuizzes from '../../pages/ebooks/ViewQuizzes';
import { searchType } from '../../services/types';

interface EbookLayoutProps {
  children?: React.ReactNode;
  toReadBooks: Book[];
  addToRead: (book: Book) => void;
}

const EbookLayout: React.FC<EbookLayoutProps> = ({ children, toReadBooks, addToRead }) => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Sidebar starts collapsed
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Book[]>([]);


  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleSearch = async (query: string) => {
    const mediaType = window.location.pathname.startsWith('/ebooks') ? 'Ebook' : 'Video';
    try {
      const results = await searchType(query, mediaType);
      setSearchResults(results); // Update search results
      console.log('Search Results:', results); // Debugging log
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  

  return (
    <div className="flex flex-col h-screen">
      <div className="container mx-auto px-4 py-6">
        <AppBar
          toggleSidebar={toggleSidebar}
          setSearchQuery={(query) => {
            setSearchQuery(query);
            handleSearch(query);
          }}
          searchQuery={searchQuery}
        />
      </div>
      <div className="p-6 mt-0 flex-grow transition-all duration-300 mt-16">
        <Sidebar isCollapsed={isCollapsed} />
        <div
          className={`p-6 mt-0 flex-grow transition-all duration-300 ${
            isCollapsed ? 'ml-16' : 'ml-64'
          }`}
        >
          {children}
          <Routes>
            <Route path="home" element={<Home addToRead={addToRead} searchResults={searchResults} />} />
            <Route path="trending" element={<Trending />} />
            <Route path="reading" element={<Reading />} />
            <Route path="to-read" element={<ToRead books={toReadBooks} addToRead={addToRead} />} />
            <Route path="read" element={<Read />} />
            <Route path="notebook" element={<Notebook />} />
            <Route path="quiz/:id" element={<Quiz />} />
            <Route path=":id" element={<BookDetails />} />
            <Route path="quiz-history" element={<QuizHistory />} />
            <Route path="read/:id" element={<Reader />} />
            <Route path="add-ebook" element={<AddEbookForm />} />
            <Route path="manage-ebook" element={<ManageEbook />} />
            <Route path="manage-ebook/edit/:id" element={<EditEbook />} />
            <Route path="manage-ebook/view-quizzes/:bookId" element={<ViewQuizzes />} />
            <Route path="manage-ebook/quiz/:id" element={<ManageQuizWrapper />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const ManageQuizWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const ebookTitle = location.state?.ebookTitle || 'Untitled Ebook';

  if (!id) {
    return <PageNotFound />;
  }

  return <ManageQuiz ebookId={Number(id)} ebookTitle={ebookTitle} />;
};

export default EbookLayout;