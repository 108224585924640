import React from 'react';

const User: React.FC = () => {
  return (
    <div>
      <h1>User Page</h1>
      <p>Manage your profile on the User page.</p>
    </div>
  );
};

export default User;
