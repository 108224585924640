import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../services/authService';

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await isAuthenticated();
      console.log('authStatus:', authStatus);
      setIsAuth(authStatus);
    };

    checkAuth();
  }, []);

  if (isAuth === null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-blue-500 border-dotted border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return isAuth ? element : <Navigate to="/signin" />;
};

export default PrivateRoute;
