import React from 'react';

const LikedVideos: React.FC = () => {
  return (
    <div>
      <h1>Liked Videos</h1>
      <p>View your liked videos.</p>
    </div>
  );
};

export default LikedVideos;
