import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import ManageBookCard from './ManageBookCardProps';
import {
  fetchUserBooks,
  fetchQuizByEbookId,
  deleteEbook,
  Quiz
} from '../../services/ebooks';
import { Book } from '../../types/ebooks/ebooks';

const ManageEbook: React.FC = () => {
  const [books, setBooks] = useState<Book[]>([]);
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [bookToDelete, setBookToDelete] = useState<Book | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadBooks();
  }, []);

  const loadBooks = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await fetchUserBooks();
      setBooks(data);
      loadQuizzes(data);
    } catch (error) {
      console.error('Error loading books:', error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          toast.error('Please log in to view your books');
          navigate('/login');
        } else if (error.response?.status === 403) {
          setError('You do not have permission to view these books.');
          toast.error('Permission denied.');
        } else {
          setError('Failed to load your books. Please try again later.');
          toast.error('Failed to load books. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again later.');
        toast.error('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };
  const loadQuizzes = async (books: Book[]) => {
    try {
      const quizPromises = books.map(book => fetchQuizByEbookId(book.id));
      const quizResults = await Promise.all(quizPromises);
      setQuizzes(quizResults.filter((quiz): quiz is Quiz => quiz !== null));
    } catch (error) {
      console.error('Error loading quizzes:', error);
      toast.warning('Some quizzes could not be loaded.');
    }
  };

  const handleCreateQuiz = (book: Book) => {
    navigate(`/ebooks/manage-ebook/quiz/${book.id}`, {
      state: { ebookTitle: book.title }
    });
  };

  const handleViewQuizzes = (bookId: number) => {
    navigate(`/ebooks/manage-ebook/view-quizzes/${bookId}`);
  };

  const handleDeleteBook = (book: Book) => {
    setBookToDelete(book);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteBook = async () => {
    if (bookToDelete) {
      try {
        await deleteEbook(bookToDelete.id);
        setBooks(books.filter(book => book.id !== bookToDelete.id));
        toast.success(`"${bookToDelete.title}" has been deleted successfully.`);
      } catch (error) {
        console.error('Error deleting ebook:', error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            toast.error('Unauthorized. Please log in again.');
          } else if (error.response?.status === 404) {
            toast.error(`"${bookToDelete.title}" not found. It may have already been deleted.`);
            setBooks(books.filter(book => book.id !== bookToDelete.id));
          } else {
            toast.error(`Failed to delete "${bookToDelete.title}". ${error.response?.data?.message || 'Please try again.'}`);
          }
        } else {
          toast.error(`An unexpected error occurred while deleting "${bookToDelete.title}". Please try again.`);
        }
      }
    }
    setIsDeleteModalOpen(false);
    setBookToDelete(null);
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-gray-700">{error}</p>
          <button
            onClick={() => loadBooks()}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (books.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">No Books Found</h2>
          <p className="text-gray-700 mb-4">You haven't created any ebooks yet.</p>
          <button
            onClick={() => navigate('/ebooks/add-ebook')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Create Your First Ebook
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Manage Your Ebooks</h1>
        <button
          onClick={() => navigate('/ebooks/add-ebook')}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Add New Ebook
        </button>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {books.map((book) => (
          <ManageBookCard
            key={book.id}
            book={book}
            onCreateQuiz={() => handleCreateQuiz(book)}
            onViewQuizzes={() => handleViewQuizzes(book.id)}
            onDeleteBook={() => handleDeleteBook(book)}
          />
        ))}
      </div>

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && bookToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-6">
              Are you sure you want to delete "{bookToDelete.title}"? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteBook}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageEbook;