import React, { createContext, useState, ReactNode } from 'react';
import { Video, initialFormData, FormContextProps } from '../../../types/forms/video';

export const FormContext = createContext<FormContextProps | undefined>(undefined);

export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<Video>(initialFormData);

  const updateFormData = (newData: Partial<Video>) => {
    setFormData(prevData => ({ ...prevData, ...newData }));
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData, resetFormData }}>
      {children}
    </FormContext.Provider>
  );
};