import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { FaChevronDown, FaSearch } from "react-icons/fa";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Channel, Playlist, Video } from "../types/domain";
import {
  fetchChannelDataByID,
  fetchPlaylistsByChannelID,
  fetchVideosByChannelID,
} from "../services/generalVideoServices";
import { useParams } from "react-router-dom";

import VideoThumbnail from "../components/VideoThumbnail";
import PlaylistThumbnail from "../components/PlaylistThumbnail";
import PageNotFound from "./PageNotFound";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ChannelPageProps {
  defaultTab?: number;
}

const updateURL = (id: string, tab: number) => {
  window.history.replaceState(
    {},
    "",
    `/videos/channel/${id}/${tab === 0 ? "videos" : "collections"}`
  );
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ChannelPage({ defaultTab = 0 }: ChannelPageProps) {
  let { id } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(defaultTab);
  const [channelData, setChannelData] = useState<Channel | null>(null);
  const [videos, setVideos] = useState<Video[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [SearchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState<string>("newest");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    updateURL(id!, newValue);
  };

  const handleSearch = () => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(2);
      setSearchTerm(e.target.value);
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;

      setChannelData(await fetchChannelDataByID(id));
      setVideos(await fetchVideosByChannelID(id));
      setPlaylists(await fetchPlaylistsByChannelID(id));
    };

    fetchData();
  }, []);

  const sortedVideos = [...videos].sort((a: any, b: any) => {
    if (sortOrder === "oldest") {
      return a.id - b.id;
    } else {
      return b.id - a.id;
    }
  });

  const filteredvideos = videos?.filter(
    (video) =>
      video.media_details.title
        .toLowerCase()
        .includes(SearchTerm.toLowerCase()) ||
      video?.media_details.description
        ?.toLowerCase()
        .includes(SearchTerm?.toLowerCase() ?? "")
  );

  const filteredPlaylists = playlists?.filter(
    (playlist) =>
      playlist.name.toLowerCase().includes(SearchTerm.toLowerCase()) ||
      playlist?.description
        ?.toLowerCase()
        .includes(SearchTerm?.toLowerCase() ?? "")
  );

  const searchResult = [...filteredvideos, ...filteredPlaylists];

  console.log(searchResult);

  if (channelData === null) {
    return <PageNotFound />;
  }

  return (
    <div>
      <Card sx={{}}>
        <CardMedia
          component="img"
          image={channelData?.cover}
          alt="Banner Image"
          sx={{ height: "200px" }}
        />
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: red[500] }}
              aria-label="recipe"
              src={channelData?.icon}
              alt={channelData?.name}
            />
          }
          title={channelData?.name}
          subheader={channelData?.subscribers + " subscribers"}
        />
        <CardContent
          sx={{
            display: "flex",
          }}
        >
          <Collapse
            in={expanded}
            timeout="auto"
            collapsedSize={40}
            sx={{ width: "100%" }}
          >
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {channelData?.description}
              </Typography>
            </CardContent>
          </Collapse>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={() => {
                setExpanded(!expanded);
              }}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ alignSelf: "flex-end" }}
            >
              <FaChevronDown />
            </ExpandMore>
          </CardActions>
        </CardContent>
      </Card>
      <Divider />
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="videos" {...a11yProps(0)} />
          <Tab label="collections" {...a11yProps(1)} />
          {/* hidden tab  */}
          <Tab sx={{ display: "none" }} label="search" {...a11yProps(2)} />{" "}
        </Tabs>
        <TextField
          id="input-with-sx"
          label={
            <Typography variant="button" color="textSecondary">
              Search
            </Typography>
          }
          variant="standard"
          onClick={() => setValue(2)}
          onChange={handleSearch()}
        />{" "}
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="w-full flex flex-col">
          <FormControl className="self-end">
            <InputLabel id="demo-simple-select-label">Sort</InputLabel>
            <Select
              value={sortOrder}
              label="Sort"
              onChange={(e) => {
                setSortOrder(e.target.value);
              }}
            >
              <MenuItem value="newest">newest</MenuItem>
              <MenuItem value="oldest">oldest</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-wrap gap-2">
          {sortedVideos.map((video, index) => (
            <VideoThumbnail key={index} video={video} />
          ))}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="flex flex-wrap gap-2">
          {filteredPlaylists.map((playlist, index) => (
            <PlaylistThumbnail key={index} playlist={playlist} />
          ))}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="flex flex-wrap gap-5">
          {searchResult.map((thumbnail, index) =>
            "playlistThumbnail" in thumbnail ? (
              <PlaylistThumbnail key={index} playlist={thumbnail as Playlist} />
            ) : (
              <VideoThumbnail key={index} video={thumbnail as Video} />
            )
          )}
        </div>
      </CustomTabPanel>
    </div>
  );
}
