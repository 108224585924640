import axios from 'axios';

const BASE_URL = process.env.REACT_APP_Django_API_URL;

// Function to create a new playlist
export const createPlaylist = async (playlistName: string, description: string) => {
  const apiUrl = `${BASE_URL}/api/p/playlist/`;
  const token = localStorage.getItem('authToken');

  // Ensure token is present and log it
  if (!token) {
    console.error('No authentication token found');
    return;
  }
  console.log('Token:', token);  // Log the token to check if it's being retrieved correctly

  const requestBody = {
    name: playlistName,
    description: description,
    type: 0, // Assuming 0 is for normal playlists
  };

  try {
    const response = await axios.post(apiUrl, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    console.error('Error creating playlist:', error);
    throw error;
  }
};

// Function to fetch user playlists
export const fetchUserPlaylists = async () => {
  const apiUrl = `${BASE_URL}/api/p/playlist/`;
  const token = localStorage.getItem('authToken');

  // Ensure token is present and log it
  if (!token) {
    console.error('No authentication token found');
    return;
  }
  console.log('Token:', token);  // Log the token to check if it's being retrieved correctly

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    console.error('Error fetching playlists:', error);
    throw error;
  }
};

// Function to add a video to a playlist
export const addVideoToPlaylist = async (playlistId: string, mediaId: number) => {
  const apiUrl = `${BASE_URL}/api/p/playlist_media/add/`;
  const token = localStorage.getItem('authToken');

  // Ensure token is present and log it
  if (!token) {
    console.error('No authentication token found');
    return;
  }
  console.log('Token:', token);  // Log the token to check if it's being retrieved correctly
  console.log(Math.floor(Date.now() / 1000));  // Current time in seconds


  const requestBody = {
    playlist: playlistId,
    media: mediaId,
  };

  try {
    const response = await axios.post(apiUrl, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    console.error('Error adding video to playlist:', error);
    throw error;
  }
};
// Function to fetch videos from a playlist
export const fetchPlaylistVideos = async (mediaId: number) => {
  const apiUrl = `${BASE_URL}/api/p/playlist/${mediaId}`;
  const token = localStorage.getItem('authToken');

  if (!token) {
    console.error('No authentication token found');
    return;
  }

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching playlist videos:', error);
    throw error;
  }
};
export const fetchCollectionVideos = async (mediaId: number) => {
  const apiUrl = `${BASE_URL}/api/p/playlist_media/${mediaId}/collection_media/`;
  const token = localStorage.getItem('authToken');

  if (!token) {
    console.error('No authentication token found');
    return;
  }

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching playlist videos:', error);
    throw error;
  }
};
