// src/pages/History.tsx
import React from 'react';
import { useWatchHistory } from '../components/watchHistoryContext';

const History: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const { watchHistory, clearWatchHistory } = useWatchHistory();

  const filteredHistory = watchHistory.filter(video =>
    video.snippet.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 text-primary">Watch History</h1>
      <button onClick={clearWatchHistory} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded">
        Clear all watch history
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredHistory.map((video, index) => (
          <div key={index} className="p-4 bg-white rounded-lg shadow-lg">
            <img src={video.snippet.thumbnails.default.url} alt={video.snippet.title} className="w-full h-auto mb-2" />
            <h3 className="text-lg font-bold">{video.snippet.title}</h3>
            <p className="text-sm text-gray-600">{video.snippet.channelTitle}</p>
            <p className="text-sm text-gray-600">
              Watched at: {video.watchedAt ? new Date(video.watchedAt).toLocaleString() : 'Unknown'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
