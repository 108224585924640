import React from 'react';
import { ProgressIndicatorProps } from '../../../types/forms/video';

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ currentStep, onStepChange, canMoveToStep }) => {
  const steps = ['Details', 'Video elements', 'Checks'];

  return (
    <div className="flex justify-between items-center mb-6">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex-1 flex flex-col items-center cursor-pointer ${
            canMoveToStep(index + 1) ? '' : 'cursor-not-allowed'
          }`}
          onClick={() => canMoveToStep(index + 1) && onStepChange(index + 1)}
        >
          <div
            className={`h-8 w-8 rounded-full flex items-center justify-center ${
              index + 1 <= currentStep ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
            }`}
          >
            {index + 1}
          </div>
          <span className={`mt-2 ${index + 1 <= currentStep ? 'text-blue-500' : 'text-gray-500'}`}>{step}</span>
          {index < steps.length - 1 && (
            <div
              className={`flex-1 h-1 ${
                index + 1 < currentStep ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgressIndicator;
