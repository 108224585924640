// src/components/AverageWatchTimeChart.tsx

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { watchTimeData } from '../mock/AnalyticsData';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AverageWatchTimeChart: React.FC = () => {
  const data = {
    labels: watchTimeData.map(data => data.month),
    datasets: [
      {
        label: 'Average Watch Time (minutes)',
        data: watchTimeData.map(data => data.watchTime),
        borderColor: '#2196F3',
        backgroundColor: 'rgba(33, 150, 243, 0.1)',
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          color: '#333',
          font: {
            size: 14
          }
        }
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#333',
        },
      },
      y: {
        display: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          color: '#333',
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div className="bg-white rounded-2xl rounded-lg p-6 w-full h-96">
      <div className="relative h-full w-full">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default AverageWatchTimeChart;
