



import React from 'react';

const Trending: React.FC = () => {
  console.log("Trending component rendered");
  return (
    <div style={{ padding: '16px', overflow: 'auto' }}>
      <h1 style={{ color: 'black' }}>To Trending Page Content</h1>
      <p>If you see this</p>
    </div>
  );
};

export default Trending;
