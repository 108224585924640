import { IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaEye, FaHeart } from "react-icons/fa";
import { addLike } from "../services/generalVideoServices";

interface CommentSectionProps {
  likesToPass: number;
  mediaID: number;
  is_liked_by_user: boolean;
}

export default function Likes({ likesToPass, mediaID, is_liked_by_user }: CommentSectionProps) {
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(is_liked_by_user);
  useEffect(() => {
    likesToPass ? setLikes(likesToPass) : setLikes(0);
  }, [likesToPass]);

  const handleLike = () => {
    addLike(mediaID);
    isLiked ? setLikes(likes - 1) : setLikes(likes + 1);
    setIsLiked(!isLiked);
  };

  return (
    <div className="flex items-center">
      <IconButton aria-label="like" onClick={() => handleLike()}>
        <FaHeart
          className={`hover:text-red-400 active:text-red-200 size-8 cursor-pointer ${
            isLiked ? "text-red-500" : "text-gray-400"
          }`}
        />
      </IconButton>
      <Typography variant="h4" className="text-gray-400">
        {" "}
        {likes}{" "}
      </Typography>
    </div>
  );
}
