// Sidebar.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBook, FaBookmark, FaRegListAlt, FaFileAlt, FaSignOutAlt } from 'react-icons/fa';
import CustomNavLink from './CustomNavLink';

const Sidebar: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/signin');
  };

  const handleTitleClick = () => {
    navigate('/');
  };

  return (
    <div
      className={`group flex flex-col h-full pb-16 fixed top-16 left-0 justify-between 
        ${isCollapsed ? 'w-16' : 'w-64'} 
        hover:w-64 bg-white text-black transition-all duration-300 shadow-2xl z-40`}
    >
      <div>
        {/* Title/Logo Section */}
        <div
          
        >
          {/* <div className={`${isCollapsed ? 'w-16' : 'w-16'} flex justify-center items-center`}>
            <FaBook className="text-xl" />
          </div> */}
          {/* <span
            className={`font-semibold whitespace-nowrap transition-all duration-300
              ${isCollapsed ? 'opacity-0 w-0' : 'opacity-100 w-auto'}
              group-hover:opacity-100 group-hover:w-auto`}
          >
            E-Library
          </span> */}
        </div>
        
        <nav className="flex flex-col mt-4">
          <CustomNavLink
            to="/ebooks/home"
            icon={FaFileAlt}
            label="Home"
            isCollapsed={isCollapsed}
          />
          
          <div className="border-t my-4" />
          
          <div>
            <h2 
              className={`px-4 text-sm font-semibold mb-2 transition-all duration-300
                ${isCollapsed ? 'opacity-0 h-0' : 'opacity-100 h-auto'}
                group-hover:opacity-100 group-hover:h-auto`}
            >
              My Activities
            </h2>
            <CustomNavLink
              to="/ebooks/reading"
              icon={FaBookmark}
              label="Reading"
              isCollapsed={isCollapsed}
            />
            <CustomNavLink
              to="/ebooks/to-read"
              icon={FaRegListAlt}
              label="To Read"
              isCollapsed={isCollapsed}
            />
            <CustomNavLink
              to="/ebooks/quiz-history"
              icon={FaBook}
              label="Quizzes"
              isCollapsed={isCollapsed}
            />
          </div>
          
          <div className="border-t my-4" />
          
          <div>
            <h2 
              className={`px-4 text-sm font-semibold mb-2 transition-all duration-300
                ${isCollapsed ? 'opacity-0 h-0' : 'opacity-100 h-auto'}
                group-hover:opacity-100 group-hover:h-auto`}
            >
              Ebook Management
            </h2>
            <CustomNavLink
              to="/ebooks/add-ebook"
              icon={FaBookmark}
              label="Add Ebook"
              isCollapsed={isCollapsed}
            />
            <CustomNavLink
              to="/ebooks/manage-ebook"
              icon={FaRegListAlt}
              label="Manage Ebook"
              isCollapsed={isCollapsed}
            />
          </div>
        </nav>
      </div>

      {/* Logout Button */}
      <button
        className="flex items-center h-12 w-full hover:bg-gray-200 transition-colors duration-300"
        onClick={handleLogout}
      >
        <div className={`${isCollapsed ? 'w-16' : 'w-16'} flex justify-center items-center`}>
          <FaSignOutAlt className="text-xl" />
        </div>
        <span
          className={`whitespace-nowrap transition-all duration-300
            ${isCollapsed ? 'opacity-0 w-0' : 'opacity-100 w-auto'}
            group-hover:opacity-100 group-hover:w-auto`}
        >
          Log Out
        </span>
      </button>
    </div>
  );
};

export default Sidebar;