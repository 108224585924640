import React, { useState } from "react";
import { Comment } from "../types/domain";
import { Avatar, Box, Button, Card, CardHeader } from "@mui/material";

import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import formatDate from "../services/dateFormatter";
import {
  FaComment,
  FaCommentAlt,
  FaCommentDollar,
  FaCommentDots,
  FaCommentMedical,
  FaCommentSlash,
  FaComments,
  FaFlag,
  FaRegFlag,
} from "react-icons/fa";

interface CommentProps {
  mediaID: number;
  comment: Comment;
  onReport: Function;
  refreshComments: Function;
}

export default function CommentDisplay({
  mediaID,
  comment,
  onReport,
  refreshComments,
}: CommentProps) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");
  const Base_API_URL = process.env.REACT_APP_Django_API_URL;
  const handleReply = () => {
    if (replyText === "") {
      alert("Comment cannot be empty");
      return;
    }

    const newComment = {
      mediaID: mediaID,
      body: replyText,
      parent: comment.id,
    };

    const token = localStorage.getItem("authToken");

    fetch(`${Base_API_URL}/api/m/comment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newComment),
    }).catch((error) => {
      alert("Failed to add comment");
    });

    setReplyText("");
    refreshComments();
  };

  return (
    <Box className="flex">
      <Card sx={{ display: "flex", width: "100%", margin: "10px" }}>
        {comment.replies.length > 0 && (
          <div
            className="w-3 m-2 bg-gray-200 rounded-md hover:bg-gray-300 cursor-pointer transition-colors duration-300"
            onClick={() => setIsCollapsed(!isCollapsed)}
          ></div>
        )}
        <div className="flex flex-col grow">
          <CardHeader
            avatar={
              <Avatar
                sx={{ bgcolor: red[500] }}
                aria-label="recipe"
                src="broken"
                // alt={comment.username}
              />
            }
            action={
              <IconButton
                aria-label="settings"
                onClick={() => onReport(comment.id)}
              >
                <FaRegFlag />
              </IconButton>
            }
            title={comment.firstName +" "+ comment.lastName}
            subheader={comment.created}
          />

          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {comment.body}
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton
              aria-label="add to favorites"
              onClick={() => setIsReplying(!isReplying)}
            >
              <FaCommentDots />
            </IconButton>
            {comment.replies?.length && (
              <IconButton
                aria-label="add to favorites"
                className="gap-1"
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                {comment.replies?.length.toString()}
                <FaComments />
              </IconButton>
            )}
          </CardActions>
          {isReplying && (
            <div>
              <textarea
                className="border-2 rounded-md m-1 p-1 w-full"
                placeholder="Reply to comment"
                value={replyText}
                onChange={(e) => setReplyText(e.target.value)}
              ></textarea>

              <Button
                variant="contained"
                color="primary"
                onClick={() => handleReply()}
              >
                Submit
              </Button>
            </div>
          )}

          <div>
            {!isCollapsed &&
              comment.replies?.map((reply) => (
                <CommentDisplay
                  key={reply.id}
                  mediaID={mediaID}
                  comment={reply}
                  onReport={onReport}
                  refreshComments={refreshComments}
                />
              ))}
          </div>
        </div>
      </Card>
    </Box>
  );
}
