import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Video, VideoListProps, FilterState } from '../../types/forms/video';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import Pagination from './Pagination';
import VideoFilter from '../filter/VideoFilter';
import {DeleteVideo} from '../../services/videos/deleteVideo';


import { fetchChannelVideos } from '../../services/uploadVideo';
import { getUserChannels } from '../../services/channelServices';
import { ChannelWithIDAsNumber as Channel } from '../../types/domain';

const getYoutubeThumbnail = (url: string) => {
  if (!url) {
    return 'https://via.placeholder.com/100'; // Return a placeholder image if the URL is undefined
  }
  const videoId = url.split('v=')[1];
  if (!videoId) {
    return 'https://via.placeholder.com/100'; // Return a placeholder image if the video ID is not found
  }
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    return `https://img.youtube.com/vi/${videoId.substring(0, ampersandPosition)}/0.jpg`;
  }
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

const VideoList: React.FC<VideoListProps> = ({ onDelete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [videos, setVideos] = useState<Video[]>([]);
  const [combinedVideos, setCombinedVideos] = useState<Video[]>([]);
  const [filteredVideos, setFilteredVideos] = useState<Video[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<FilterState>({
    type: 'all',
    minViews: 0,
    maxViews: 100,
    category: 'all'
  });

  useEffect(() => {
    const loadChannels = async () => {
      try {
        const userChannels = await getUserChannels();
        setChannels(userChannels || []);
        if (userChannels && userChannels.length > 0) {
          setSelectedChannel(userChannels[0].id.toString()); // Set first channel as default
          localStorage.setItem('channelId', userChannels[0].id.toString());
        }
      } catch (error) {
        console.error('Error fetching user channels:', error);
      }
    };
    loadChannels();
  }, []);

  // Debounce logic to prevent double requests (optional)
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>(undefined);

  // Fetch videos for the selected channel when it changes
  useEffect(() => {
    const loadVideos = async () => {
      try {
        if (selectedChannel) {
          const fetchedVideos = await fetchChannelVideos(selectedChannel);
          setCombinedVideos(fetchedVideos); // Reset the combined videos with the fetched ones
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };
    loadVideos();
  }, [selectedChannel]); // Remove debounceTimeout from dependencies if it's not necessary
  
  // Combine mock data with fetched videos
  useEffect(() => {
    setCombinedVideos([...combinedVideos, ...videos]);
  }, [videos]);

  // Apply filters whenever combined videos or filters change
  useEffect(() => {
    applyFilters();
  }, [combinedVideos, activeFilters]);
  useEffect(() => {
    if (location.state && location.state.message) {
      toast.success(location.state.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleCreateVideo = () => {
    if (!selectedChannel) {
      toast.error("Please select a channel before creating a video.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    navigate('/videos/your-videos/form', { state: { channelId: selectedChannel } });
  };

  const handleEditVideo = (id: number) => {
    navigate(`/videos/your-videos/form/${id}`, { state: { channelId: selectedChannel } });
  };

  const handleDeleteVideo = async (id: number) => {
    // Show a confirmation dialog before deleting
    const confirmed = window.confirm("Are you sure you want to delete this video?");
    if (!confirmed) return;
  
    try {
      // Await the result of the DeleteVideo function
      await DeleteVideo(id);
  
      // Update the state to remove the deleted video
      setFilteredVideos((prevVideos) => prevVideos.filter((video) => video.id !== id));
  
      // Optionally show a success message
      toast.success("Video deleted successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      // Handle error during deletion
      console.error('Error deleting video:', error);
      toast.error("Failed to delete the video.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
    const handleFilterChange = (key: string, value: any) => {
    setActiveFilters({ ...activeFilters, [key]: value });
  };

  const handleChannelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newChannelId = e.target.value;
    // Ensure we're not setting the same channel ID again
    if (newChannelId !== selectedChannel) {
      setSelectedChannel(newChannelId); 
      localStorage.setItem('channelId', newChannelId);
    }
  };



  const applyFilters = () => {
    let updatedVideos = [...combinedVideos];
    if (activeFilters.type !== 'all') {
      updatedVideos = updatedVideos.filter(video => video.mediaFormat === activeFilters.type);
    }
    if (activeFilters.category !== 'all') {
      updatedVideos = updatedVideos.filter(video => video.category === activeFilters.category);
    }
    updatedVideos = updatedVideos.filter(video => video.views >= activeFilters.minViews && video.views <= activeFilters.maxViews);
    setFilteredVideos(updatedVideos);
  };

  const currentVideos = filteredVideos.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredVideos.length / itemsPerPage);

  return (
    <div className="container mx-auto p-4">
      <div className="mb-8 p-4">
        <h1 className="text-3xl font-bold text-black">Channel content</h1>
        <div className="flex justify-between items-center mt-4">
          <div className="flex space-x-4">
            <button className="text-blue-500 border-b-2 border-blue-500">Videos</button>
          </div>
          <div className="flex items-center space-x-4">
            <select
              value={selectedChannel}
              onChange={handleChannelChange}
              className="px-4 py-2 border border-gray-300 rounded"
            >
              {channels.map(channel => (
                console.log("e"+channel),
                <option key={channel.id} value={channel.id}>
                  {channel.name}
                </option>
              ))}
            </select>
            <button
              onClick={handleCreateVideo}
              className="flex items-center px-4 py-2 border border-red-500 text-red-500 rounded hover:bg-red-500 hover:text-white transition"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              CREATE
            </button>
          </div>
        </div>
      </div>
      <VideoFilter filters={activeFilters} onFilterChange={handleFilterChange} />
      <div className="overflow-x-auto bg-white shadow-lg rounded-2xl">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Video</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Views</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Media Type</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Likes</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interview Date</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentVideos.map((video) => (
              <tr key={video.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <Link to={`/videos/watch/${video.id}`} target="_blank" rel="noopener noreferrer">
                    <img src={getYoutubeThumbnail(video.url)} alt={video.title} width="100" />
                  </Link>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{video.title}</div>
                  <div className="text-sm text-gray-500">{video.description}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.views}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.mediaFormat}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.likes}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.category}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {video.interviewDate ? new Date(video.interviewDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    }) : 'N/A'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => handleEditVideo(video.id)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button
                    onClick={() => handleDeleteVideo(video.id)}
                    className="ml-4 text-red-600 hover:text-red-900"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        itemsPerPage={itemsPerPage}
        totalItems={filteredVideos.length}
        setItemsPerPage={setItemsPerPage}
      />
    </div>
  );
};

export default VideoList;
