import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchQuizzesByEbookId, deleteQuiz, deleteQuestion, patchQuiz, patchQuestion } from '../../services/ebooks';

import { ChevronDown, ChevronUp, Edit, Trash2, Save } from 'lucide-react';


export interface Question {
    id: number;
    question_text: string;
    type: 'multiple_choice' | 'true_false';
    options: string[] | null;
    correct_answer: string | null;
    correct_option: string | null;
  }
  
  export interface Quiz {
    id: number;
    title: string;
    questions: Question[];
  }


  const ViewQuizzes: React.FC = () => {
    const { bookId } = useParams<{ bookId: string }>();
    const [quizzes, setQuizzes] = useState<Quiz[]>([]);
    const [expandedQuiz, setExpandedQuiz] = useState<number | null>(null);
    const [editingQuiz, setEditingQuiz] = useState<number | null>(null);
    const [editedTitle, setEditedTitle] = useState<string>('');
    const [editingQuestion, setEditingQuestion] = useState<number | null>(null);
    const [editedQuestion, setEditedQuestion] = useState<Question | null>(null);
    const navigate = useNavigate();
  
    useEffect(() => {
      if (bookId) {
        loadQuizzes(parseInt(bookId, 10));
      }
    }, [bookId]);
  
    const loadQuizzes = async (ebookId: number) => {
      try {
        const fetchedQuizzes = await fetchQuizzesByEbookId(ebookId);
        setQuizzes(fetchedQuizzes);
      } catch (error) {
        console.error('Error loading quizzes:', error);
        toast.error('Failed to load quizzes. Please try again.');
      }
    };
  
    const handleDeleteQuiz = async (quizId: number) => {
      if (window.confirm('Are you sure you want to delete this quiz?')) {
        try {
          await deleteQuiz(quizId);
          setQuizzes(quizzes.filter(quiz => quiz.id !== quizId));
          toast.success('Quiz deleted successfully');
        } catch (error) {
          console.error('Error deleting quiz:', error);
          toast.error('Failed to delete quiz. Please try again.');
        }
      }
    };
  
    const handleEditQuiz = (quizId: number, currentTitle: string) => {
      setEditingQuiz(quizId);
      setEditedTitle(currentTitle);
    };
  
    const handleSaveQuiz = async (quizId: number) => {
      try {
        await patchQuiz(quizId, { title: editedTitle });
        setQuizzes(quizzes.map(quiz => 
          quiz.id === quizId ? { ...quiz, title: editedTitle } : quiz
        ));
        setEditingQuiz(null);
        toast.success('Quiz updated successfully');
      } catch (error) {
        console.error('Error updating quiz:', error);
        toast.error('Failed to update quiz. Please try again.');
      }
    };
  
    const handleDeleteQuestion = async (quizId: number, questionId: number) => {
      if (window.confirm('Are you sure you want to delete this question?')) {
        try {
          await deleteQuestion(questionId);
          setQuizzes(quizzes.map(quiz => 
            quiz.id === quizId 
              ? { ...quiz, questions: quiz.questions.filter(q => q.id !== questionId) }
              : quiz
          ));
          toast.success('Question deleted successfully');
        } catch (error) {
          console.error('Error deleting question:', error);
          toast.error('Failed to delete question. Please try again.');
        }
      }
    };
  
    const toggleQuizExpansion = (quizId: number) => {
      setExpandedQuiz(expandedQuiz === quizId ? null : quizId);
    };
  
    const handleEditQuestion = (question: Question) => {
        setEditingQuestion(question.id);
        setEditedQuestion({ ...question });
      };
    
      const handleSaveQuestion = async (quizId: number) => {
        if (!editedQuestion) return;
    
        try {
          const updatedQuestion = await patchQuestion(editedQuestion.id, editedQuestion);
          setQuizzes(quizzes.map(quiz => 
            quiz.id === quizId 
              ? { ...quiz, questions: quiz.questions.map(q => q.id === updatedQuestion.id ? updatedQuestion : q) }
              : quiz
          ));
          setEditingQuestion(null);
          setEditedQuestion(null);
          toast.success('Question updated successfully');
        } catch (error) {
          console.error('Error updating question:', error);
          toast.error('Failed to update question. Please try again.');
        }
      };
    
      const handleQuestionChange = (field: keyof Question, value: string | string[]) => {
        if (!editedQuestion) return;
        setEditedQuestion({ ...editedQuestion, [field]: value });
      };
    return (
      <div className="container mx-auto px-4 py-8 bg-gray-100 min-h-screen">
        <h1 className="text-4xl font-bold mb-8 text-center text-indigo-700">Quizzes for Ebook #{bookId}</h1>
        {quizzes.length === 0 ? (
          <p className="text-center text-xl text-gray-600">No quizzes found for this ebook.</p>
        ) : (
          <div className="space-y-6">
            {quizzes.map(quiz => (
              <div key={quiz.id} className="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
                <div className="p-6 flex justify-between items-center bg-gradient-to-r from-indigo-500 to-purple-600 text-white">
                  {editingQuiz === quiz.id ? (
                    <input
                      type="text"
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      className="flex-grow mr-4 px-3 py-2 bg-white text-gray-800 rounded"
                    />
                  ) : (
                    <h2 className="text-2xl font-semibold">{quiz.title}</h2>
                  )}
                  <div className="flex space-x-3">
                    {editingQuiz === quiz.id ? (
                      <button
                        onClick={() => handleSaveQuiz(quiz.id)}
                        className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-300"
                      >
                        <Save size={18} />
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEditQuiz(quiz.id, quiz.title)}
                        className="p-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition duration-300"
                      >
                        <Edit size={18} />
                      </button>
                    )}
                    <button
                      onClick={() => handleDeleteQuiz(quiz.id)}
                      className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300"
                    >
                      <Trash2 size={18} />
                    </button>
                    <button
                      onClick={() => toggleQuizExpansion(quiz.id)}
                      className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300"
                    >
                      {expandedQuiz === quiz.id ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                    </button>
                  </div>
                </div>
                {expandedQuiz === quiz.id && (
                <div className="p-6 space-y-4">
                  {quiz.questions.map((question: Question) => (
                    <div key={question.id} className="bg-gray-50 rounded-lg p-4 shadow transition-all duration-300 hover:shadow-md">
                      {editingQuestion === question.id ? (
                        <div className="space-y-2">
                          <input
                            type="text"
                            value={editedQuestion?.question_text || ''}
                            onChange={(e) => handleQuestionChange('question_text', e.target.value)}
                            className="w-full px-3 py-2 border rounded"
                          />
                          <select
                            value={editedQuestion?.type || ''}
                            onChange={(e) => handleQuestionChange('type', e.target.value as 'multiple_choice' | 'true_false')}
                            className="w-full px-3 py-2 border rounded"
                          >
                            <option value="multiple_choice">Multiple Choice</option>
                            <option value="true_false">True/False</option>
                          </select>
                          {editedQuestion?.type === 'multiple_choice' && (
                            <div>
                              {editedQuestion.options?.map((option, index) => (
                                <input
                                  key={index}
                                  type="text"
                                  value={option}
                                  onChange={(e) => {
                                    const newOptions = [...(editedQuestion.options || [])];
                                    newOptions[index] = e.target.value;
                                    handleQuestionChange('options', newOptions);
                                  }}
                                  className="w-full px-3 py-2 border rounded mt-2"
                                />
                              ))}
                              <select
                                value={editedQuestion.correct_option || ''}
                                onChange={(e) => handleQuestionChange('correct_option', e.target.value)}
                                className="w-full px-3 py-2 border rounded mt-2"
                              >
                                {editedQuestion.options?.map((option, index) => (
                                  <option key={index} value={option}>{option}</option>
                                ))}
                              </select>
                            </div>
                          )}
                          {editedQuestion?.type === 'true_false' && (
                            <select
                              value={editedQuestion.correct_answer || ''}
                              onChange={(e) => handleQuestionChange('correct_answer', e.target.value)}
                              className="w-full px-3 py-2 border rounded"
                            >
                              <option value="True">True</option>
                              <option value="False">False</option>
                            </select>
                          )}
                          <button
                            onClick={() => handleSaveQuestion(quiz.id)}
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
                          >
                            Save Question
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="flex justify-between items-center mb-2">
                            <p className="font-medium text-lg text-gray-800">{question.question_text}</p>
                            <div className="space-x-2">
                              <button
                                onClick={() => handleEditQuestion(question)}
                                className="p-1 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition duration-300"
                              >
                                <Edit size={14} />
                              </button>
                              <button
                                onClick={() => handleDeleteQuestion(quiz.id, question.id)}
                                className="p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300"
                              >
                                <Trash2 size={14} />
                              </button>
                            </div>
                          </div>
                          <p className="text-sm text-gray-600 mb-2">Type: {question.type}</p>
                          {question.type === 'multiple_choice' && question.options && (
                            <ul className="list-disc list-inside mt-2 space-y-1">
                              {question.options.map((option, index) => (
                                <li key={index} className={`${option === question.correct_option ? 'font-bold text-green-600' : 'text-gray-700'}`}>
                                  {option}
                                </li>
                              ))}
                            </ul>
                          )}
                          {question.type === 'true_false' && question.correct_answer && (
                            <p className="mt-2 font-semibold">Correct Answer: <span className="text-green-600">{question.correct_answer}</span></p>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ViewQuizzes;