import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
  combinedSegmentTranscript,
  Segment,
  Transcript,
  Video,
} from "../types/domain";
import { FaSearch } from "react-icons/fa";
import { isRTL } from "../services/script";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { fetchTranscriptsByID } from "../services/generalVideoServices";

export function TranscriptWidget({
  videoId,
  fullTranscript,
  playerRef,
}: {
  videoId: number;
  fullTranscript: string;
  playerRef: React.RefObject<ReactPlayer>;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [totalTranscriptSearchTerm, setTotalTranscriptSearchTerm] =
    useState("");
  const [combinedSegmentsTranscripts, setCombinedSegmentsTranscripts] =
    useState<combinedSegmentTranscript[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [availbleLanguages, setAvailableLanguages] = useState<string[]>([]);
  const [iscollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { combinedSegmentTranscripts, availbleLanguages } =
        await fetchTranscriptsByID(videoId);

      setCombinedSegmentsTranscripts(combinedSegmentTranscripts);
      setAvailableLanguages(availbleLanguages);
      setSelectedLanguage(availbleLanguages[0]);
    };

    fetchData();
  }, [videoId]);

  const handleLanguageSelection = (language: string) => {
    setSelectedLanguage(language);
  };

  const transcriptSelector = (language: string | null, index: number) => {
    return language != null ? (
      <div
        key={index}
        className={`m-2 p-2 rounded-md border-2 cursor-pointer ${
          selectedLanguage === language ? "bg-blue-200" : ""
        }`}
        onClick={() => handleLanguageSelection(language)}
      >
        {selectedLanguage}
      </div>
    ) : null;
  };

  const secondsToTimeFormat = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedTime = `${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    return formattedTime;
  };

  const timeToSeconds = (time: string) => {
    const [hours, minutes, seconds] = time.split(":").map((t) => parseInt(t));
    return hours * 3600 + minutes * 60 + seconds;
  };

  const segmentDisplayer = (
    combinedSegmentTranscript: combinedSegmentTranscript,
    selectedLanguage: string | null,
    index: number
  ) => {
    const segment = combinedSegmentTranscript.segment;
    const transcript =
      combinedSegmentTranscript.transcripts.find(
        (transcript) => transcript.transcriptionLanguage === selectedLanguage
      ) ?? combinedSegmentTranscript.transcripts[0];

    const highlightMatch = (text: string) => {
      if (!searchTerm) return text;

      const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
      return parts.map((part, i) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={i} style={{ backgroundColor: "yellow" }}>
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return (
      <>
        <ListItem
          disablePadding
          key={index}
          onClick={() => {
            playerRef.current?.seekTo(
              timeToSeconds(segment.startTime),
              "seconds"
            );
          }}
        >
          <ListItemButton>
            <ListItemText
              primary={segment.startTime + " - " + segment.endTime}
              secondary={
                <div
                  style={{
                    direction: isRTL(transcript?.transcription) ? "rtl" : "ltr",
                    textAlign: isRTL(transcript?.transcription)
                      ? "right"
                      : "left",
                  }}
                >
                  <div className="m-2">
                    <strong>{highlightMatch(segment?.title)}</strong>
                  </div>

                  <div className="m-2">
                    {highlightMatch(transcript?.transcription)}
                  </div>
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
        <Divider />
      </>
    );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleTotalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTotalTranscriptSearchTerm(event.target.value);
  };

  const filteredCSTs = combinedSegmentsTranscripts.filter(
    (CST) =>
      CST.segment.description
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      CST.segment.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      CST.transcripts.find((transcript) =>
        transcript.transcriptionLanguage === selectedLanguage
          ? transcript.transcription
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            transcript.transcription
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : false
      )
  );

  const handleChange = (event: SelectChangeEvent) => {
    const selected = event.target.value;
    setSelectedLanguage(selected ?? null);
  };

  const highlightMatchForTotalTranscript = (text: string) => {
    if (!totalTranscriptSearchTerm) return text;
    const parts = text.split(
      new RegExp(`(${totalTranscriptSearchTerm})`, "gi")
    );
    return parts.map((part, i) =>
      part.toLowerCase() === totalTranscriptSearchTerm.toLowerCase() ? (
        <span key={i} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return combinedSegmentsTranscripts.length === 0 ? (
    <></>
  ) : (
    <>
      <Grid container spacing={2}>
        {/* Left section: Collapsible segment card */}
        <Grid item xs={iscollapsed ? 1 : 4}>
          <Card
            sx={{
              width: `${iscollapsed ? "fit-content" : "100%"}`,
              height: "fit-content",
            }}
          >
            <Button
              onClick={() => {
                setIsCollapsed(!iscollapsed);
              }}
            >
              {iscollapsed ? "Transcript" : "Hide"}
            </Button>
            {!iscollapsed && (
              <>
                <CardHeader
                  title={
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Transcript Version
                      </InputLabel>
                      <Select
                        value={selectedLanguage ?? ""}
                        label="Transcript Version"
                        onChange={handleChange}
                      >
                        {availbleLanguages?.map((language, index) => (
                          <MenuItem key={index} value={language}>
                            {language}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                  subheader={
                    <Box sx={{ display: "flex", alignItems: "end" }}>
                      <TextField
                        sx={{ width: "100%" }}
                        id="input-with-sx"
                        label={
                          <div className="flex gap-1">
                            <FaSearch className="h-6 w-auto" />
                            <>Search</>
                          </div>
                        }
                        variant="standard"
                        onChange={handleSearch}
                      />
                    </Box>
                  }
                />
                <CardContent>
                  <List
                    sx={{
                      overflow: "auto",
                      height: "75vh",
                    }}
                  >
                    {filteredCSTs?.map((CST, index) => (
                      <div key={index}>
                        {segmentDisplayer(CST, selectedLanguage, index)}
                      </div>
                    ))}
                  </List>
                </CardContent>
              </>
            )}
          </Card>
        </Grid>
        {/* Right section: Full transcript card */}
        <Grid item xs={iscollapsed ? 11 : 8}>
          {!iscollapsed && (
            <Card className="h-full">
              <CardHeader
                title="Full Transcript"
                subheader={
                  <Box sx={{ display: "flex", alignItems: "end" }}>
                    <TextField
                      sx={{ width: "100%" }}
                      id="input-with-sx"
                      label={
                        <div className="flex gap-1">
                          <FaSearch className="h-6 w-auto" />
                          <>Search</>
                        </div>
                      }
                      variant="standard"
                      onChange={handleTotalSearch}
                    />
                  </Box>
                }
              />
              <CardContent>
                {highlightMatchForTotalTranscript(fullTranscript)}
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}
