
const BASE_URL = process.env.REACT_APP_Django_API_URL;
export const fetchCategories = async () => {

    try {
        const response = await fetch(`${BASE_URL}/api/m/category/`);
        if (!response.ok) {
        throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        
        // Extract categories from the 'results' field
        if (!Array.isArray(data.results)) {
        throw new Error('Category data is not an array');
        }
        console.log(data.results);
        return data.results;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return []; 
    }
};