import { Card, Typography } from "@mui/material";
import { FaHeartBroken } from "react-icons/fa";

export default function PageNotFound() {
  return (
    <Card className="h-full flex flex-col items-center justify-center">
      <FaHeartBroken className="text-9xl" />
      <Typography variant="h1">Oh no...</Typography>
      <Typography variant="body1">This page could not be found.</Typography>
    </Card>
  );
}
