import React, { createContext, useState, useContext, ReactNode } from 'react';
import { VideoSimple as Video } from '../types/domain'; // Ensure you import the Video type from types.ts

interface Playlist {
  name: string;
  videos: Video[];
}

interface PlaylistContextProps {
  playlists: Playlist[];
  addVideoToPlaylist: (video: Video, playlistName: string) => void;
  createPlaylist: (name: string) => void;
  deletePlaylist: (name: string) => void;
  deleteVideoFromPlaylist: (videoId: string, playlistName: string) => void;
  searchPlaylists: (query: string) => Playlist[];
}

const PlaylistContext = createContext<PlaylistContextProps | undefined>(undefined);

interface PlaylistProviderProps {
  children: ReactNode;
}

export const PlaylistProvider: React.FC<PlaylistProviderProps> = ({ children }) => {
  const [playlists, setPlaylists] = useState<Playlist[]>(() => {
    const savedPlaylists = localStorage.getItem('playlists');
    return savedPlaylists ? JSON.parse(savedPlaylists) : [];
  });

  const addVideoToPlaylist = (video: Video, playlistName: string) => {
    setPlaylists((prevPlaylists) => {
      const updatedPlaylists = prevPlaylists.map((playlist) => {
        if (playlist.name === playlistName) {
          return { ...playlist, videos: [...playlist.videos, video] };
        }
        return playlist;
      });
      localStorage.setItem('playlists', JSON.stringify(updatedPlaylists));
      return updatedPlaylists;
    });
  };

  const createPlaylist = (name: string) => {
    setPlaylists((prevPlaylists) => {
      const updatedPlaylists = [...prevPlaylists, { name, videos: [] }];
      localStorage.setItem('playlists', JSON.stringify(updatedPlaylists));
      return updatedPlaylists;
    });
  };

  const deletePlaylist = (name: string) => {
    setPlaylists((prevPlaylists) => {
      const updatedPlaylists = prevPlaylists.filter((playlist) => playlist.name !== name);
      localStorage.setItem('playlists', JSON.stringify(updatedPlaylists));
      return updatedPlaylists;
    });
  };

  const deleteVideoFromPlaylist = (videoId: string, playlistName: string) => {
    setPlaylists((prevPlaylists) => {
      const updatedPlaylists = prevPlaylists
        .map((playlist) => {
          if (playlist.name === playlistName) {
            const updatedVideos = playlist.videos.filter((video) => video.id.videoId !== videoId);
            if (updatedVideos.length === 0) {
              return null;
            }
            return { ...playlist, videos: updatedVideos };
          }
          return playlist;
        })
        .filter((playlist): playlist is Playlist => playlist !== null);
      localStorage.setItem('playlists', JSON.stringify(updatedPlaylists));
      return updatedPlaylists;
    });
  };

  const searchPlaylists = (query: string): Playlist[] => {
    return playlists.filter((playlist) =>
      playlist.name.toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <PlaylistContext.Provider
      value={{ playlists, addVideoToPlaylist, createPlaylist, deletePlaylist, deleteVideoFromPlaylist, searchPlaylists }}
    >
      {children}
    </PlaylistContext.Provider>
  );
};

export const usePlaylists = () => {
  const context = useContext(PlaylistContext);
  if (!context) {
    throw new Error('usePlaylists must be used within a PlaylistProvider');
  }
  return context;
};
