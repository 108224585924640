import React, { useState, useEffect } from 'react';
import { ModalProps } from '../../../types/forms/video';

const Modal: React.FC<ModalProps> = ({ type, onClose, onSave, initialData }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [picture, setPicture] = useState<File | null>(null);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);

  useEffect(() => {
    if (initialData) {
      setFirstName(firstName || '');
      setLastName(lastName || '');
      setPhone(initialData.phoneNumber);
    }
  }, [initialData]);

  const validateFirstName = (name: string) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!name.trim()) {
      setFirstNameError('First name is required.');
    } else if (!nameRegex.test(name)) {
      setFirstNameError('First name can only contain letters.');
    } else {
      setFirstNameError(null);
    }
  };

  const validateLastName = (name: string) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!name.trim()) {
      setLastNameError('Last name is required.');
    } else if (!nameRegex.test(name)) {
      setLastNameError('Last name can only contain letters.');
    } else {
      setLastNameError(null);
    }
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^[0-9]*$/;
    if (phone && !phoneRegex.test(phone)) {
      setPhoneError('Phone number can only contain digits.');
    } else {
      setPhoneError(null);
    }
  };

 
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setPicture(event.target.files[0]);
    }
  };

  const handleSave = () => {
    validateFirstName(firstName);
    validateLastName(lastName);
    validatePhone(phone || '');

    if (!firstNameError && !lastNameError && !phoneError  && firstName.trim() && lastName.trim()) {
      onSave(firstName, lastName, picture || undefined, phone);
      onClose();
    } else {
      if (!firstName.trim()) {
        setFirstNameError('First name is required.');
      }
      if (!lastName.trim()) {
        setLastNameError('Last name is required.');
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
        <h2 className="text-2xl font-semibold mb-4">{type === 'interviewee' ? 'Add Interviewee' : 'Add Interviewer'}</h2>
        <div className="mb-4">
          <label className="block text-xl font-medium mb-1">First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              validateFirstName(e.target.value);
            }}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {firstNameError && <p className="text-red-500 text-sm mt-1">{firstNameError}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-xl font-medium mb-1">Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              validateLastName(e.target.value);
            }}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {lastNameError && <p className="text-red-500 text-sm mt-1">{lastNameError}</p>}
        </div>
        {/* <div className="mb-4">
          <label className="block text-xl font-medium mb-1">Phone (Optional)</label>
          <input
            type="text"
            value={phone || ''}
            onChange={(e) => {
              setPhone(e.target.value);
              validatePhone(e.target.value);
            }}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {phoneError && <p className="text-red-500 text-sm mt-1">{phoneError}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-xl font-medium mb-1">Email (Optional)</label>
          <input
            type="email"
            value={email || ''}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value);
            }}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
        </div>
        <div className="mb-4">
          <label className="block text-xl font-medium mb-1">Upload Picture (Optional)</label>
          <input
            type="file"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div> */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 p-2 bg-gray-500 text-white rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={
              !!firstNameError ||
              !!lastNameError ||
              !!phoneError ||
              !firstName.trim() ||
              !lastName.trim()
            }
            className={`p-2 text-white rounded-full transition focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              !!firstNameError ||
              !!lastNameError ||
              !!phoneError ||
              !firstName.trim() ||
              !lastName.trim()
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
