import React, { useState, useEffect } from 'react';
import { FaCheckCircle, FaExclamationTriangle, FaTrashAlt, FaInfoCircle } from 'react-icons/fa';
import { fetchTakenQuizzes } from '../../services/ebooks';

const QuizHistory: React.FC = () => {
  const [history, setHistory] = useState<any[]>([]);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const fetchedHistory = await fetchTakenQuizzes();
        setHistory(fetchedHistory);
      } catch (error) {
        console.error("Failed to fetch history:", error);
      }
    };
    fetchHistory();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-white-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-center mb-12 text-indigo-900 tracking-tight">
          Your Quiz Journey
        </h2>
        {history.length === 0 ? (
          <div className="bg-white rounded-xl shadow-lg p-8 text-center">
            <FaInfoCircle className="text-6xl text-indigo-500 mx-auto mb-4" />
            <p className="text-xl text-gray-700 font-medium">
              Your quiz history is waiting to be written. Take a quiz and start your learning adventure!
            </p>
          </div>
        ) : (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {history.map((item, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:-translate-y-1">
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-indigo-800 mb-2">{item.quiz_title}</h3>
                  <div className="flex items-center justify-between mt-4">
                    <p className="text-lg text-gray-600 font-medium">Score</p>
                    <span className={`text-3xl font-bold ${
                      item.score >= 75 ? 'text-green-500' : 'text-red-500'
                    }`}>
                      {item.score}%
                    </span>
                  </div>
                </div>
                <div className={`px-6 py-3 ${
                  item.score >= 75 ? 'bg-green-100' : 'bg-red-100'
                }`}>
                  <p className={`text-sm font-medium ${
                    item.score >= 75 ? 'text-green-800' : 'text-red-800'
                  }`}>
                    {item.score >= 75 ? (
                      <span className="flex items-center">
                        <FaCheckCircle className="mr-2" /> Great job!
                      </span>
                    ) : (
                      <span className="flex items-center">
                        <FaExclamationTriangle className="mr-2" /> Keep practicing!
                      </span>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizHistory;