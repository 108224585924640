import React, { useState, useEffect } from 'react';
import { Collaborator } from '../../types/domain';
import { getCollaborators, addCollaborator , removeCollaborator , updateCollaboratorRole, inviteCollaborator  } from '../../services/channelServices';
import { useChannel } from '../../contexts/ChannelContext';
import AcceptInvitationModal from './AcceptInvitationModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CollaboratorManagement: React.FC = () => {
  const { channelId,refreshChannels } = useChannel();
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [newCollaboratorEmail, setNewCollaboratorEmail] = useState('');
  const [newCollaboratorRole, setNewCollaboratorRole] = useState('Editor');
  const [error, setError] = useState<string | null>(null);
  const [isAcceptInvitationModalOpen, setIsAcceptInvitationModalOpen] = useState(false);


  useEffect(() => {

      fetchCollaborators();
    
  }, [channelId]);

  const handleInvitationAccepted = async () => {
    
    await fetchCollaborators();
    await refreshChannels();
    setIsAcceptInvitationModalOpen(false);
    toast.success('Invitation accepted successfully!');

  };

  const fetchCollaborators = async () => {
    setCollaborators([]);
    setNewCollaboratorEmail('');
    setNewCollaboratorRole('Editor');
    setError(null);
    if (channelId) {
      try {
        const fetchedCollaborators = await getCollaborators(channelId);
        setCollaborators(fetchedCollaborators);
      } catch (error) {
        console.error('Error fetching collaborators:', error);
        toast.error('Failed to fetch collaborators. Please try again.');

      }
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleAddCollaborator = async () => {
    if (!channelId) return;

    if (!validateEmail(newCollaboratorEmail)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {

      await inviteCollaborator(channelId, newCollaboratorEmail, newCollaboratorRole);;
      setNewCollaboratorEmail('');
      setNewCollaboratorRole('editor');
      setError('');
      await fetchCollaborators();
      toast.success('Invitation sent successfully!');

      
    } catch (error) {
      console.error('Error adding collaborator:', error);
      setError('Failed to add collaborator. Please try again.');
      toast.error('Failed to send invitation. Please try again.');

    }
  };

  const handleRemoveCollaborator = async (email: string) => {
    if (!channelId) return;

    try {
      await removeCollaborator(channelId, email);
      await fetchCollaborators();
      toast.success('Collaborator removed successfully!');
    } catch (error) {
      console.error('Error removing collaborator:', error);
      setError('Failed to remove collaborator. Please try again.');
      toast.error('Failed to remove collaborator. Please try again.');

    }
  
  };

  const handleUpdateRole = async (email: string, newRole: string) => {
    if (!channelId) return;

    try {
      await updateCollaboratorRole(channelId, email, newRole);
      await fetchCollaborators();
      toast.success('Collaborator role updated successfully!');
    } catch (error) {
      console.error('Error updating collaborator role:', error);
      setError('Failed to update collaborator role. Please try again.');
      toast.error('Failed to update collaborator role. Please try again.');

    }
  };
  
  


  return (
    <div className="p-6 bg-white rounded-3xl shadow-md mx-auto mt-5 max-w-4xl">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className="mb-10">
      <h1 className="block text-2xl font-bold text-blue-700 mb-4">Channel Invitations</h1>
        <button
          className="bg-blue-500 text-white rounded-lg px-6 py-3"
          onClick={() => setIsAcceptInvitationModalOpen(true)}
        >
          Accept Invitations
        </button>
      </div>
      <div className="mb-10">
        <h1 className="block text-2xl font-bold text-blue-700 mb-4">Add Collaborator</h1>
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          <div className="relative flex-grow mb-4 sm:mb-0">
            <label className="absolute -top-3 left-3 px-2 bg-white text-gray-500 text-xl">Email Address</label>
            <input
              type="text"
              className={`border-2 rounded-lg p-4 w-full focus:outline-none ${error ? 'border-red-500' : 'border-gray-300'}`}
              placeholder="Enter email address"
              value={newCollaboratorEmail}
              onChange={(e) => setNewCollaboratorEmail(e.target.value)}
            />
            {error && <span className="text-red-500 mt-1 block">{error}</span>}
          </div>
          <select
            className="border-2 border-gray-300 rounded-lg p-4 mb-4 sm:mb-0 sm:mr-4"
            value={newCollaboratorRole}
            onChange={(e) => setNewCollaboratorRole(e.target.value)}
          >
            <option value="Owner">Owner</option>
            <option value="Editor">Editor</option>
          </select>
          <button
            className="bg-blue-500 text-white rounded-lg px-6 py-3"
            onClick={handleAddCollaborator}
          >
            Invite
          </button>
        </div>
      </div>
      
      <div>
        <h3 className="block text-2xl font-bold text-blue-700 mb-4">Current Collaborators</h3>
        <ul>
          {collaborators.map(c => (
            <li key={c.email} className="flex flex-col sm:flex-row sm:items-center sm:justify-between border-b py-3">
              <span>{c.email}</span>
              <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 mt-4 sm:mt-0">
                <select
                  className="border-2 border-gray-300 rounded-lg p-2 mb-4 sm:mb-0"
                  value={c.role}
                  onChange={(e) => handleUpdateRole(c.email, e.target.value)}
                >
                  <option value="Owner">Owner</option>
                  <option value="Editor">Editor</option>
                </select>
                <button
                  className="text-red-500"
                  onClick={() => handleRemoveCollaborator(c.email)}
                >
                  Remove
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <AcceptInvitationModal
        isOpen={isAcceptInvitationModalOpen}
        onClose={() => setIsAcceptInvitationModalOpen(false)}
        onInvitationAccepted={handleInvitationAccepted}
      />
    </div>

  );
};

export default CollaboratorManagement;
