import React from 'react';
import { useLocation } from 'react-router-dom';
import { VideoSimple as Video } from '../types/domain'; // Import the Video type from the common types file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faEye, faUser } from '@fortawesome/free-solid-svg-icons';

const VideoDetails: React.FC = () => {
  const location = useLocation();
  const { video } = location.state || {}; // Ensure location.state is not null

  if (!video) {
    return <div>No video details available</div>; // Render a fallback UI if video is not available
  }

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <div className="relative pb-56 mb-4" style={{ paddingBottom: '56.25%' }}>
        <iframe
          className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
          src={`https://www.youtube.com/embed/${video.id.videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={video.snippet.title}
        ></iframe>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
        <h1 className="text-3xl font-bold mb-2">{video.snippet.title}</h1>
        <div className="flex items-center space-x-4 text-gray-600 mb-2">
          <FontAwesomeIcon icon={faUser} className="text-lg" />
          <p className="text-lg font-medium">{video.snippet.channelTitle}</p>
        </div>
        <div className="flex items-center space-x-4 text-gray-600 mb-2">
          <FontAwesomeIcon icon={faEye} className="text-lg" />
          <p className="text-lg">{video.views ?? 0} views</p>
        </div>
        <div className="flex items-center space-x-4 text-gray-600 mb-2">
          <FontAwesomeIcon icon={faThumbsUp} className="text-lg" />
          <p className="text-lg">{video.likes ?? 0} likes</p>
        </div>
       
      </div>
    </div>
  );
};

export default VideoDetails;
