import React, { createContext, useState, useContext, ReactNode } from 'react';
import { VideoSimple as Video } from '../types/domain';

interface WatchHistoryContextProps {
  watchHistory: Video[];
  addToWatchHistory: (video: Video) => void;
  clearWatchHistory: () => void;
}

const WatchHistoryContext = createContext<WatchHistoryContextProps | undefined>(undefined);

export const WatchHistoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [watchHistory, setWatchHistory] = useState<Video[]>([]);

  const addToWatchHistory = (video: Video) => {
    setWatchHistory(prevHistory => [...prevHistory, { ...video, watchedAt: new Date() }]);
  };

  const clearWatchHistory = () => {
    setWatchHistory([]);
  };

  return (
    <WatchHistoryContext.Provider value={{ watchHistory, addToWatchHistory, clearWatchHistory }}>
      {children}
    </WatchHistoryContext.Provider>
  );
};

export const useWatchHistory = () => {
  const context = useContext(WatchHistoryContext);
  if (!context) {
    throw new Error('useWatchHistory must be used within a WatchHistoryProvider');
  }
  return context;
};
