import axios from 'axios';
const BASE_URL = process.env.REACT_APP_Django_API_URL;
export const addToWatchLater = async (mediaId:number) => {
    

    const apiUrl = `${BASE_URL}/api/p/watchlater/add/`;
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      console.error('No authentication token found');
      return;
    }
    console.log('Adding media to Watch Later...');
    const requestBody = {
      media_id: mediaId,
    };
  
    try {
      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      console.log('Media added to Watch Later:', response.data);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error adding media to Watch Later:', error);
    }
  };
  export const retreiveWatchLater = async () => {

    const apiUrl = `${BASE_URL}/api/p/watchlater/get/`;
    const token = localStorage.getItem('authToken');
  
    if (!token) {
      console.error('No authentication token found');
      return;
    }
  
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      console.log('Watch Later:', response.data);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error fetching Watch Later:', error);
    }
  }