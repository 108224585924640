
const BASE_URL = process.env.REACT_APP_Django_API_URL;
const authToken = localStorage.getItem('authToken');
export const DeleteVideo = async (id: number) => {
    const response = await fetch(`${BASE_URL}/api/v/video/${id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken
            }`
        }
    });
    
    if (!response.ok) {
        throw new Error(`Failed to delete video: ${response.statusText}`);
    }
    }
