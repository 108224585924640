import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  Dialog,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { MdArrowDropDownCircle } from "react-icons/md";
import { Person } from "../types/domain";
import { useState } from "react";

interface PersonDisplayerProps {
  person: Person;
}

export default function PersonDisplayer({ person }: PersonDisplayerProps) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          src={
            person.pictureURL
              ? person.pictureURL
              : "unresolving string to force the avatr componenet the first letter of the alt value"
          }
          alt={person.firstName}
        />
      </ListItemAvatar>
      <ListItemText
        primary={person.firstName + " " + person.lastName}
      />
    </ListItem>
  );
}
