export interface VideoLayoutProps {
  children: React.ReactNode;
}
export interface SidebarProps {
  isCollapsed: boolean;
  className?: string;
}

// export interface Ebook {
//   id: number;
//   title: string;
//   description: string;
//   author: string;
//   publicationDate: string;
//   file: File | null;
//   [key: string]: any; // Add more fields as needed
// }

// export interface Book {
//   title: string;
//   author: string;
//   year: number;
//   cover: string;
//   description: string;
//   price: string;
//   rating: number;
//   date: string;
//   genre: string; // New attribute for genre
// }
export interface Transcript {
  transcription: string;
  transcriptionLanguage: string;
}

export interface AppBarProps {
  toggleSidebar: () => void;
}

export interface Segment {
  title: string;
  startTime: string;
  endTime: string;
  description: string; // Add this line
  transcripts: {
    language: string;
    transcript: string;
  }[];
}

export interface Person {
  firstName: string;
  lastName: string;
  picture?: string;
  phoneNumber?: string;
  email?: string;
  role: number;
}


export interface Video {
  id: number; // Unique identifier for the video
  transcript: Transcript;
  title: string; // Title of the video
  description: string; // Detailed description of the video
  category: string; // Main category the video belongs to
  collectionName: string; // Name of the collection this video is part of
  customCategory?: string; // Optional custom category provided by the user
  customCollectionName?: string; // Optional custom collection name provided by the user
  url: string; // URL link to access the video
  mediaFormat: string; // Format of the media (e.g., mp4, avi)
  originalLanguage: string; // Language in which the video was originally recorded
  segments: Segment[]; // Array of segments associated with the video
  interviewDate: string; // Date of the interview; should handle date validation
  location: {
    type: 'City' | 'Monument'; // Specifies whether the location is a city or a monument
    name: string; // Name of the location (e.g., city or monument)
    monument_name?: string; // Monument name, if applicable
    city_name?: string; // City name, if applicable
    monument_image?: File; // Image of the monument, if applicable
    city_image?: File; 
  };
  acknowledgment: string; // Acknowledgment field for user input (e.g., credits, references)
  interviewees: Person[]; // Array of interviewee objects
  interviewers: Person[]; // Array of interviewer objects
  views: number; // Number of views the video has received
  comments: number; // Number of comments on the video
  likes: number; // Number of likes the video has received
  visibility: string; // Visibility status of the video (e.g., 2 for public, 1 for private)
  restriction: string; // Restrictions placed on the video (e.g., age restrictions)
  fullTranscript: string; // Full transcript of the video
  media_details: {
    title: string; // Title of the media as stored in the backend
    uploaderID: number; // ID of the user who uploaded the video
    description: string; // Description of the media as stored in the backend
    uploadDate: string; // Date when the media was uploaded
    type: string; // Type of the media (e.g., interview, documentary)
    originalLanguage: string; // Original language of the media
    categoryID: { name: string }; // Category ID with its name
    channelID: number; // ID of the channel that uploaded the media
  };
  channelId?: number; // Optional channel ID for the video
  videoURL: string; // Direct URL to the video for playback
  size: number; // Size of the video file in bytes
  participants: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: number; // Role in the video (e.g., 1 for interviewer, 2 for interviewee)
  }[]; // Array of participants involved in the video
  topics: string[]; // Topics covered in the video (e.g., "Oral Literature", "Colonial History")
  important_persons: string[]; // Important persons mentioned in the video (e.g., "King Hassan II")
}

export const initialFormData: Video = {
  id: 0,
  title: '',
  description: '',
  category: '',
  collectionName: '',
  customCategory: '',
  customCollectionName: '',
  fullTranscript: '', // Added fullTranscript field
  url: '',
  mediaFormat: 'Video',
  originalLanguage: '',
  transcript: {
    transcription: '',
    transcriptionLanguage: '',
  },

  segments: [],
  interviewDate: '',
  location: {
    type: 'City', // Specifies the type of location
    name: '', // Name of the location
    monument_name: '', // Monument name if applicable
    city_name: '', // City name if applicable
  },
  acknowledgment: '',
  interviewees: [],
  interviewers: [],
  views: 0,
  comments: 0,
  likes: 0,
  visibility: "public", // Setting the default visibility as 2 (e.g., public)
  restriction: '1',
  media_details: {
    title: '',
    uploaderID: 1,
    description: '',
    uploadDate: '',
    type: '1',
    originalLanguage: '',
    categoryID: { name: '' },
    channelID: 0,
  },
  videoURL: '',
  size: 0,
  participants: [], // Empty array for participants, to be populated later
  topics: [], // Empty array for topics
  important_persons: [], // Empty array for important persons
};
export interface FormContextProps {
  formData: Video;
  updateFormData: (newData: Partial<Video>) => void;
  resetFormData: () => void;
}
export interface SegmentModalProps {
  onClose: () => void;
  onSave: (segment: Segment) => void;
  segment: Segment | null;
  segments: Segment[]; // Add this line
}
export interface SegmentWithIndex extends Segment {
  index?: number;
}
export interface VideoListProps {
  videos: Video[];
  onDelete: (id: number) => void;
}
export interface VideoFormProps {
  videos: Video[];
  onAdd: (video: Video) => void;
  onUpdate: (video: Video) => void;
}
// Example ModalProps interface update
export interface ModalProps {
  type: 'interviewee' | 'interviewer';
  onClose: () => void;
  onSave: (
    firstName: string, 
    lastName: string, 
    picture?: File, 
    phoneNumber?: string, 
    email?: string
  ) => void;
  initialData?: Person | null; // Add this line
}



export interface ProgressIndicatorProps {
  currentStep: number;
  onStepChange: (step: number) => void;
  canMoveToStep: (step: number) => boolean;
}
export interface DetailsProps {
  formData: {
    title: string;
    media_details: {
      title: string; // Title of the media as stored in the backend
      uploaderID: number; // ID of the user who uploaded the video
      description: string; // Description of the media as stored in the backend
      uploadDate: string; // Date when the media was uploaded
      type: string; // Type of the media (e.g., interview, documentary)
      originalLanguage: string; // Original language of the media
      categoryID: { name: string }; // Category ID with its name
      channelID: number; // ID of the channel that uploaded the media
    };
    description: string;
    category: string;
    collectionName: string;
    customCategory?: string;
    customCollectionName?: string;
    visibility: string; 
    restriction: string;
    originalLanguage: string; // Added originalLanguage
    videoURL: string; // Added videoURL field
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleNext: () => void;
}


export interface VideoElementsProps {
  formData: Video
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleNext: () => void;
  handleBack: () => void;
  updateFormData: (newData: Partial<VideoElementsProps['formData']>) => void;
}

export interface checkProps {
  formData: {
    interviewDate: string;
    location: { 
      type: 'City' | 'Monument'; // Specifies whether the location is a city or a monument
      monument_name?: string; // Name of the monument (if applicable)
      city_name?: string; // Name of the city (if applicable)
      monument_image?: File; // 
      city_image?: File; 
    };
    acknowledgment: string;
    interviewees: Person[];
    interviewers: Person[];
    participants: Person[]; // Ensure participants are part of formData
    topics: string[];
    important_persons: string[];
    transcript: Transcript;
    fullTranscript: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleBack: () => void;
  handleSubmit: (e: React.FormEvent) => void;
  handleSavePerson: (
    type: 'interviewee' | 'interviewer' | 'participant', // Added 'participant' here
    firstName: string,
    lastName: string,
    picture?: File,
    phoneNumber?: string,
    email?: string,
    index?: number
  ) => void;
}


export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
  totalItems: number;
  setItemsPerPage: (value: number) => void;
}
export interface FilterState {
  type: string;
  minViews: number;
  maxViews: number;
  category: string;
}
export interface QuantityInputProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
}
export interface VideoFilterProps {
  filters: FilterState;
  onFilterChange: (key: string, value: any) => void;
}