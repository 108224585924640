import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Video } from "../types/domain";
import { useNavigate } from "react-router-dom";
import { getYoutubeThumbnail } from "../utils/youtube";

interface VideoThumbnailProps {
  video: Video;
}

export default function VideoThumbnail({ video }: VideoThumbnailProps) {
  const navigate = useNavigate();
  console.log("eeeeeee", video);
  return (
    <Card
      onClick={() => {
        navigate(`/videos/watch/${video.id}`);
      }}
      sx={{
        cursor: "pointer",
        transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
        boxShadow: "0 0 5px rgba(0,0,0,0.2)",
        ":hover": {
          transform: "scale(1.01)",
          boxShadow: "0 0 5px rgba(0,0,0,1)",
        },
        ":active": {
          transform: "scale(0.99)",
        },
        width: "16vw",
      }}
    >
      <div className="flex justify-end items-center">
        <Typography
          variant="body1"
          className="absolute self-end bg-gray-700 rounded-md text-white "
          style={{
            margin: "0.5rem",
          }}
          
        >
          {video.duration}
        </Typography>

        <CardMedia
          component="img"
          image={getYoutubeThumbnail(video.videoURL)}
          alt={"could not load thumbnail"}
          sx={{ height: "9vw" }}
        />
      </div>
      <CardContent className="flex justify-between">
        <Typography variant="body1">{video.media_details.title}</Typography>
        <Typography variant="body2">{video.interviewDate}</Typography>
      </CardContent>
    </Card>
  );
}
