import React from 'react';

const Notebook: React.FC = () => {
    return (
        <div>
          <h1>Notebook Page</h1>
          <p>Discover Notebook page.</p>
        </div>
      );
};
export default Notebook;