import React, { useState,useEffect } from 'react';
import { checkProps } from '../../../types/forms/video';
import Modal from './IntervieweeModal'; // Adjust the import path as necessary
import { fetchLanguages } from '../../../services/language';

const Checks: React.FC<checkProps> = ({
  formData,
  handleChange,
  handleBack,
  handleSubmit,
  handleSavePerson,
}) => {
  const [showModal, setShowModal] = useState<{
    type: 'interviewee' | 'interviewer' | null;
    index: number | null;
  }>({ type: null, index: null });


  const openModal = (type: 'interviewee' | 'interviewer', index: number | null = null) => {
    setShowModal({ type, index });
  };

  const closeModal = () => {
    setShowModal({ type: null, index: null });
  };

  const [locationType, setLocationType] = useState<string | null>(null);
  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const data = await fetchLanguages();
        console.log("lang",data)
        const options = data.map((lang: { value: number; label: string }) => ({
          value: lang.value,
          label: lang.label
        }));
        
        setLanguageOptions(options);
      } catch (error) {
        console.error('Failed to load languages:', error);
      } finally {
        setLoading(false);
      }
    };

    getLanguages();
  }, []);
  const savePerson = (
    firstName: string,
    lastName: string,
    picture?: File,
    phoneNumber?: string,
    email?: string
  ) => {
    if (showModal.type !== null) {
      handleSavePerson(showModal.type, firstName, lastName, picture, phoneNumber, email, showModal.index ?? undefined);
    }
    closeModal();
  };
  
  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;
  
    if (files && files.length > 0) {
      // Handle file inputs (like city_image or monument_image)
      const file = files[0];
      
      handleChange({
        target: {
          name, // e.g., 'location.city_image'
          value: file, // Pass the file directly
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    } else {
      // Handle text inputs (like city_name or monument_name)
      handleChange(e); // Directly pass the event for text inputs
    }
  };
  
  
  const handleRemovePerson = (type: 'interviewee' | 'interviewer', index: number) => {
    const updatedList =
      type === 'interviewee'
        ? formData.interviewees.filter((_, i) => i !== index)
        : formData.interviewers.filter((_, i) => i !== index);

    const event = {
      target: { name: type === 'interviewee' ? 'interviewees' : 'interviewers', value: updatedList },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

  const handleEditPerson = (type: 'interviewee' | 'interviewer', index: number) => {
    openModal(type, index);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day for accurate comparison

    if (selectedDate > currentDate) {
      alert('Interview date cannot be in the future.');
      return;
    }

    handleChange(e);
  };
  console.log("g",formData.acknowledgment)



  return (
    <div className="space-y-6 p-6 max-w-3xl mx-auto">
      <div className="relative">
        <div>
          <label className="block text-lg font-medium text-gray-700">Location Type</label>
          <div className="mt-2 space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="locationType"
                value="city"
                className="form-radio h-5 w-5 text-blue-600"
                onChange={() => setLocationType('city')}
              />
              <span className="ml-2 text-gray-700">City</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="locationType"
                value="monument"
                className="form-radio h-5 w-5 text-blue-600"
                onChange={() => setLocationType('monument')}
              />
              <span className="ml-2 text-gray-700">Monument</span>
            </label>
          </div>
        </div>

        {locationType === 'city' && (
          <div>
            <label className="block text-lg font-medium text-gray-700">City Name</label>
            <input
              type="text"
              name="location.city_name"
              value={formData.location.city_name}
              onChange={handleLocationChange}
              placeholder="Enter the city name"
              className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
            />
            <label className="block text-lg font-medium text-gray-700 mt-4">City Image</label>
            <div className="mt-2">
              <label className="block w-full py-2 px-4 text-center bg-blue-600 text-white rounded-md cursor-pointer hover:bg-blue-700">
                <span>Upload City Image</span>
                <input
                  type="file"
                  name="location.city_image"
                  accept="image/*"
                  onChange={handleLocationChange}
                  className="hidden"
                />
              </label>
              {formData.location.city_image && (
                <div className="mt-4">
                  <p className="text-green-600">City image uploaded successfully!</p>
                </div>
              )}
            </div>
          </div>
        )}

        {locationType === 'monument' && (
          <div>
            <label className="block text-lg font-medium text-gray-700">Monument Name</label>
            <input
              type="text"
              name="location.monument_name"
              value={formData.location.monument_name}
              onChange={handleLocationChange}
              placeholder="Enter the monument name"
              className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
            />
            <label className="block text-lg font-medium text-gray-700 mt-4">Monument Image</label>
            <div className="mt-2">
              <label className="block w-full py-2 px-4 text-center bg-blue-600 text-white rounded-md cursor-pointer hover:bg-blue-700">
                <span>Upload Monument Image</span>
                <input
                  type="file"
                  name="location.monument_image"
                  accept="image/*"
                  onChange={handleLocationChange}
                  className="hidden"
                />
              </label>
              {formData.location.monument_image && (
                <div className="mt-4">
                  <p className="text-green-600">Monument image uploaded successfully!</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="relative">
        <label htmlFor="acknowledgment" className="block text-lg font-medium text-gray-700">
          Acknowledgment
        </label>
        <input
          type="text"
          name="acknowledgment"
          id="acknowledgment"
          value={formData.acknowledgment}
          onChange={handleChange}
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>
      <div>
        <label htmlFor="topics" className="block text-lg font-medium text-gray-700">Topics</label>
        <input
          type="text"
          name="topics"
          id="topics"
          placeholder="Enter topics separated by commas (e.g. Finance,AI,Healthcare)"
          value={formData.topics} 
          onChange={handleChange} 
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>
      <div className="relative">
  <label htmlFor="important_persons" className="block text-lg font-medium text-gray-700">
    Important People
  </label>
  <input
    type="text"
    name="important_persons"
    id="important_persons"
    value={formData.important_persons}
    onChange={handleChange}
    placeholder="Enter important persons separated by commas"
    className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
  />
</div>

      <div className="relative">
  <label htmlFor="transcript" className="block text-lg font-medium text-gray-700">
    Transcript
  </label>
  <textarea
    name="fullTranscript"
    id="transcript"
    value={formData.fullTranscript}
    onChange={handleChange}
    placeholder="Enter the transcription"
    className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
  />
</div>

<div className="relative mt-4">
    <label htmlFor="transcriptionLanguage" className="block text-lg font-medium text-gray-700">
      Transcript Language
    </label>
    <select
      id="transcriptionLanguage"
      name="transcript.transcriptionLanguage"
      value={formData.transcript.transcriptionLanguage}
      onChange={handleChange}  // Assuming this updates the formData
      className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
    >
      <option value="" disabled>Select a language</option>
      {languageOptions.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>


{/* Buttons to Add Interviewees and Interviewers */}
<div className="flex space-x-4">
        <button
          type="button"
          onClick={() => openModal('interviewee')}
          className="mt-4 py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Add Interviewee
        </button>
        <button
          type="button"
          onClick={() => openModal('interviewer')}
          className="mt-4 py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Add Interviewer
        </button>
      </div>

      {/* Modal for Adding or Editing Interviewee or Interviewer */}
      {showModal.type && (
        <Modal
          type={showModal.type}
          onClose={closeModal}
          onSave={savePerson}
          initialData={
            showModal.index !== null
              ? showModal.type === 'interviewee'
                ? formData.interviewees[showModal.index]
                : formData.interviewers[showModal.index]
              : undefined
          }
        />
      )}
      <div>
        <h3 className="text-lg font-medium text-gray-700">Interviewees</h3>
        {formData.interviewees.length > 0 ? (
          <ul className="space-y-2">
            {formData.interviewees.map((person, index) => (
              <li key={index} className="flex justify-between items-center p-2 border rounded-md">
                <div>
                  <strong>{person.firstName} {person.lastName}</strong>
                  {person.phoneNumber && <p>Phone: {person.phoneNumber}</p>}
                </div>
                <div className="space-x-2">
                  <button
                    onClick={() => handleEditPerson('interviewee', index)}
                    className="text-blue-600 hover:underline"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleRemovePerson('interviewee', index)}
                    className="text-red-600 hover:underline"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No interviewees added yet.</p>
        )}
      </div>

      {/* Display Interviewers */}
      <div>
        <h3 className="text-lg font-medium text-gray-700">Interviewers</h3>
        {formData.interviewers.length > 0 ? (
          <ul className="space-y-2">
            {formData.interviewers.map((person, index) => (
              <li key={index} className="flex justify-between items-center p-2 border rounded-md">
                <div>
                  <strong>{person.firstName} {person.lastName}</strong>
                  {person.phoneNumber && <p>Phone: {person.phoneNumber}</p>}
                </div>
                <div className="space-x-2">
                  <button
                    onClick={() => handleEditPerson('interviewer', index)}
                    className="text-blue-600 hover:underline"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleRemovePerson('interviewer', index)}
                    className="text-red-600 hover:underline"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No interviewers added yet.</p>
        )}
      </div>

      

      {/* Form submission and navigation buttons */}
      <div className="flex justify-between mt-4">
        <button onClick={handleBack} className="py-2 px-4 bg-gray-600 text-white rounded-md hover:bg-gray-700">
          Back
        </button>
        <button onClick={handleSubmit} className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700">
          Submit
        </button>
      </div>
    </div>

  );
};

export default Checks;
