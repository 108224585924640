import React, { useState, useEffect } from 'react';
import BookCard from '../../components/ebooks/BookCard';
import GenreFilter from '../../components/ebooks/GenreFilter'; // Adjust the path as necessary
import { Book } from '../../types/ebooks/ebooks';
import { fetchBooks } from '../../services/ebooks';

const itemsPerPage = 8;

// Mock data for additional ebooks

const sortBooks = (books: Book[], sortBy: string): Book[] => {
  switch (sortBy) {
    case 'popularity':
      return books; // Assuming books are already sorted by popularity
    case 'newest':
      return [...books].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    default:
      return books;
  }
};

interface HomeProps {
  addToRead: (book: Book) => void;
  searchResults?: Book[]; // Make searchResults optional
}

const PaginatedBookList: React.FC<{ books: Book[], addToRead: (book: Book) => void }> = ({ books, addToRead }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<string>('newest');

  const sortedBooks = sortBooks(books, sortBy);
  const totalPages = Math.ceil(sortedBooks.length / itemsPerPage);

  const paginatedBooks = sortedBooks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <div className="flex justify-start mb-4">
        <label htmlFor="sort" className="mr-2">Sort by:</label>
        <select
          id="sort"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="border px-2 py-1 rounded"
        >
          <option value="newest">Newest Arrivals</option>
        </select>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {paginatedBooks.map((book) => (
          <BookCard
            key={book.id}
            id={book.id}
            title={book.title}
            author={book.author}
            year={book.year}
            cover={book.cover}
            description={book.description}
            pdfUrl={book.pdfUrl}
            date={book.date}
            genre={book.genre}
            addToRead={addToRead}
            removeFromToRead={() => {}}
          />
        ))}
      </div>
      <div className="flex justify-center mt-6">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 bg-gray-300 rounded disabled:opacity-50"
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`px-4 py-2 mx-1 ${
              currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 mx-1 bg-gray-300 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

const Home: React.FC<HomeProps> = ({ addToRead, searchResults }) => {
  const [books, setBooks] = useState<Book[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedGenre, setSelectedGenre] = useState<string>('all');

  useEffect(() => {
    const getBooks = async () => {
      try {
        const data = await fetchBooks();
        // Combine mock books with fetched books
        setBooks([...books, ...data]);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    // If there are search results, display them; otherwise, fetch books
    if (searchResults && searchResults.length > 0) {
      setBooks(searchResults);
      setLoading(false);
    } else {
      getBooks();
    }
  }, [searchResults]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Filter books by genre
  const genres = Array.from(new Set(books.map((book) => book.genre)));
  const filteredBooks = selectedGenre === 'all' ? books : books.filter((book) => book.genre === selectedGenre);

  return (
    <div className="p-6 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">All Books</h1>
      <div className="flex-grow flex justify-end">
        <GenreFilter genres={genres} selectedGenre={selectedGenre} onGenreChange={setSelectedGenre} />
      </div>
      <PaginatedBookList books={filteredBooks} addToRead={addToRead} />
    </div>
  );
};

export default Home;
