import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { getUserChannels } from "../services/channelServices";
import { ChannelWithIDAsNumber as Channel } from "../types/domain";

type ChannelContextType = {
  channelId: number | null;
  setChannelId: (id: number | null) => void;
  channels: Channel[];
  setChannels: React.Dispatch<React.SetStateAction<Channel[]>>;
  refreshChannels: () => Promise<void>;
};

const ChannelContext = createContext<ChannelContextType | undefined>(undefined);

export const ChannelProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [channelId, setChannelId] = useState<number | null>(null);
  const [channels, setChannels] = useState<Channel[]>([]);

  const refreshChannels = useCallback(async () => {
    try {
      const fetchedChannels = await getUserChannels();
      setChannels(fetchedChannels);
      if (fetchedChannels.length > 0) {
        setChannelId(fetchedChannels[0].id);
      } else {
        setChannelId(null);
      }
    } catch (error) {
      console.error("Error refreshing channels:", error);
    }
  }, []);

  return (
    <ChannelContext.Provider
      value={{
        channelId,
        setChannelId,
        channels,
        setChannels,
        refreshChannels,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export const useChannel = () => {
  const context = useContext(ChannelContext);
  if (context === undefined) {
    throw new Error("useChannel must be used within a ChannelProvider");
  }
  return context;
};
