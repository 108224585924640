import React, { useState } from 'react';
import VideoList from './VideoList';
import VideoForm from '../forms/addVideo/VideoForm';
import { Video } from '../../types/forms/video';
import { ChannelProvider } from '../../contexts/ChannelContext';
import {DeleteVideo} from '../../services/videos/deleteVideo';
const VideoManager: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const handleAddVideo = (video: Video) => {
    setVideos([...videos, video]);
  };
  const handleUpdateVideo = (updatedVideo: Video) => {
    setVideos(videos.map(video => video.id === updatedVideo.id ? updatedVideo : video));
  };
  const handleDeleteVideo = (id: number) => {
    DeleteVideo(id);
    setVideos(videos.filter(video => video.id !== id));

  };
  return (
    <ChannelProvider>

      <VideoList videos={videos} onDelete={handleDeleteVideo} />
      <VideoForm videos={videos} onAdd={handleAddVideo} onUpdate={handleUpdateVideo} />
    </ChannelProvider>
  );
};
export default VideoManager;