import React from 'react';
import { ManageBookCardProps } from '../../types/ebooks/ebooks';
import { PlusCircle, Eye, Trash2 } from 'lucide-react';

const ManageBookCard: React.FC<ManageBookCardProps> = ({ book, onDeleteBook, onCreateQuiz, onViewQuizzes }) => {
  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-105">
      <div className="p-6 space-y-4">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-2xl font-bold text-gray-800 mb-2">{book.title}</h3>
            <p className="text-gray-600">By {book.author}</p>
          </div>
          <span className="inline-block bg-blue-100 text-blue-800 text-sm font-semibold px-3 py-1 rounded-full">
            {book.year}
          </span>
        </div>
        
        <div className="border-t border-gray-200 pt-4">
          <div className="flex flex-wrap -mx-2">
            <div className="px-2 w-full sm:w-1/3 mb-2 sm:mb-0">
              <button
                onClick={() => onDeleteBook(book.id)}
                className="w-full bg-red-700 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300 flex items-center justify-center"
              >
                <Trash2 size={18} className="mr-2" />
                Delete Ebook
              </button>
            </div>
            <div className="px-2 w-full sm:w-1/3 mb-2 sm:mb-0">
              <button
                onClick={() => onCreateQuiz(book)}
                className="w-full bg-lime-400 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300 flex items-center justify-center"
              >
                <PlusCircle size={18} className="mr-2" />
                Create Quiz
              </button>
            </div>
            <div className="px-2 w-full sm:w-1/3">
              <button
                onClick={() => onViewQuizzes(book.id)}
                className="w-full bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300 flex items-center justify-center"
              >
                <Eye size={18} className="mr-2" />
                View Quizzes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageBookCard;