import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
  fetchVideoDataByID,
  fetchChannelDataByID,
} from "../services/generalVideoServices";
import { Video } from "../types/domain";
import { TranscriptWidget } from "../components/TranscriptWidget";
import { useParams } from "react-router-dom";
import CommentSection from "../components/CommentSection";
import VideoSection from "../components/VideoSection";
import VideoNotFound from "../components/VideoNotFound";
import PageNotFound from "./PageNotFound";
import { Button } from "@mui/material";

export const Watch: React.FC = () => {
  let { id } = useParams() as { id: string };
  const videoId = parseInt(id);

  const [videoData, setVideoData] = useState<Video | null>(null);
  const [channelData, setChannelData] = useState<any>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setVideoData(await fetchVideoDataByID(id));
    };

    fetchData();
  }, []);

  useEffect(() => {
    // (mojib) eagerly fetches the entire channel data, even stuff we dont really use, like other videos
    const fetchData = async () => {
      videoData &&
        setChannelData(
          await fetchChannelDataByID(videoData.media_details.channelID)
        );
    };

    fetchData();
  }, [videoData]);

  if (videoData === null) {
    return <PageNotFound />;
  } else {
    return (
      <div className="flex flex-col gap-4">
        <VideoSection
          videoData={videoData}
          playerRef={playerRef}
          channelData={channelData}
        />
        <TranscriptWidget videoId={videoId} playerRef={playerRef} fullTranscript={videoData.fullTranscript} />
        <CommentSection videoId={videoId} />
      </div>
    );
  }
};
