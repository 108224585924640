export const viewsData = [
    { month: 'January', views: 100 },
    { month: 'February', views: 200 },
    { month: 'March', views: 150 },
    { month: 'April', views: 300 },
    { month: 'May', views: 250 },
    { month: 'June', views: 400 },
    { month: 'July', views: 350 },
    { month: 'August', views: 500 },
    { month: 'September', views: 450 },
    { month: 'October', views: 600 },
    { month: 'November', views: 550 },
    { month: 'December', views: 700 },
  ];
  
  export const totalViewsPerMonth = [
    { month: 'January', watchTime: 120 },
    { month: 'February', watchTime: 130 },
    { month: 'March', watchTime: 140 },
    { month: 'April', watchTime: 150 },
    { month: 'May', watchTime: 160 },
    { month: 'June', watchTime: 170 },
    { month: 'July', watchTime: 180 },
    { month: 'August', watchTime: 190 },
    { month: 'September', watchTime: 200 },
    { month: 'October', watchTime: 210 },
    { month: 'November', watchTime: 220 },
    { month: 'December', watchTime: 230 },
  ];
  
  export const watchTimeData = [
    { month: 'January', watchTime: 120 },
    { month: 'February', watchTime: 130 },
    { month: 'March', watchTime: 140 },
    { month: 'April', watchTime: 150 },
    { month: 'May', watchTime: 160 },
    { month: 'June', watchTime: 170 },
    { month: 'July', watchTime: 180 },
    { month: 'August', watchTime: 190 },
    { month: 'September', watchTime: 200 },
    { month: 'October', watchTime: 210 },
    { month: 'November', watchTime: 220 },
    { month: 'December', watchTime: 230 },
  ];

  export const mostViewedVideosData = [
    {
      id: 1,
      url: 'https://www.youtube.com/watch?v=eI1r6hS8gF0&t=860s',
      title: 'Sample Video 1',
      description: 'Description for video 1',
      views: 15,
      mediaFormat: 'Audio', 
      likes: 100,
      category: 'Film',
      interviewDate: '2024-06-01',
    },
    {
      id: 2,
      url: 'https://www.youtube.com/watch?v=eI1r6hS8gF0&t=860s',
      title: 'Sample Video 2',
      description: 'Description for video 2',
      views: 30,
      mediaFormat: 'Video', 
      likes: 200,
      category: 'Documentary',
      interviewDate: '2024-06-02',
    },
  ];
  
  