import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { retreiveWatchLater } from '../services/watchLater';
import {getYoutubeThumbnail} from '../utils/helpers/getYoutubeThumbnail';
interface VideoDetails {
  id: number;
  videoURL: string;
  duration: string;
  media_details: {
    title: string;
    description: string;
    category_name: string;
  };
}

interface WatchLaterData {
  user_id: number;
  watch_later: {
    playlist: number;
    media: number;
    added_at: string;
    added_by: number;
    video_details: VideoDetails | null; // Allow for null video_details
  }[];
}

const WatchLater: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [watchLaterVideos, setWatchLaterVideos] = useState<VideoDetails[]>([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchWatchLaterVideos = async () => {
      try {
        const response: WatchLaterData = await retreiveWatchLater();
        const videos = response.watch_later
          .filter(item => item.video_details !== null) // Ensure video_details is not null
          .map(item => item.video_details as VideoDetails); // Type assertion to ensure non-null values
        setWatchLaterVideos(videos);
      } catch (error) {
        console.error('Failed to retrieve watch later videos:', error);
      }
    };

    fetchWatchLaterVideos();
  }, []);

  const filteredVideos = watchLaterVideos.filter(video =>
    video.media_details?.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleVideoClick = (id: number) => {
    navigate(`/videos/watch/${id}`); 
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 text-primary">Watch Later</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredVideos.length > 0 ? (
          filteredVideos.map((video) => (
            <div
              key={video.id}
              className="p-4 bg-white rounded-lg shadow-lg transform transition duration-300 hover:scale-105 relative cursor-pointer"
              onClick={() => handleVideoClick(video.id)}
            >
              <img
                src={getYoutubeThumbnail(video.videoURL)}
                alt={video.media_details.title}
                className="w-full h-32 object-cover rounded-t-lg"
              />
              <div className="p-2">
                <h3 className="text-lg font-semibold text-primary">{video.media_details.title}</h3>
                <p className="text-sm text-gray-600">{video.media_details.description}</p>
                <p className="text-sm text-gray-600">Category: {video.media_details.category_name}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No videos in Watch Later list.</p>
        )}
      </div>
    </div>
  );
};

export default WatchLater;
