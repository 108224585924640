import React, { useEffect, useState } from 'react';
import { DetailsProps } from '../../../types/forms/video';
import { fetchCategories } from '../../../services/CategoryService';
import { Category, Collection } from '../../../types/media';
import { fetchCollections } from '../../../services/uploadVideo';

const Details: React.FC<DetailsProps> = ({ formData, handleChange, handleNext }) => {
  const [categories, setCategories] = useState<Category[]>([]); 
  const [collections, setCollections] = useState<Collection[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchCategories();
        setCategories(result); // Store category objects instead of names
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchData();
    console.log("cat",categories)
  }, []);
  
  useEffect(() => {
    const fetchCollectionsData = async () => {
      try {
        const result = await fetchCollections(); // Pass the channel ID as an argument
        console.log("res",result)
        setCollections(Array.isArray(result.results) ? result.results : [] as Collection[])
        console.log("coll",collections)
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };
    fetchCollectionsData();
  }, []);

  const handleNextClick = () => {
    if (formData.media_details.title.trim() === '') {
      alert('Title is required');
      return;
    }
    handleNext();
  };
  

  return (
    <div className="space-y-6 p-6 max-w-3xl mx-auto">
      <div className="relative">
        <label htmlFor="title" className="block text-lg font-medium text-gray-700">
          Title <span className="text-red-500">(required)</span>
        </label>
        <input
          type="text"
          name="media_details.title"
          id="title"
          value={formData.media_details.title}
          onChange={handleChange}
          required
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
          maxLength={100}
        />
        <div className="absolute right-3 bottom-3 text-sm text-gray-500">{formData.media_details.title.length}/100</div>
      </div>
      <div className="relative">
        <label htmlFor="description" className="block text-lg font-medium text-gray-700">Description</label>
        <textarea
          name="media_details.description"
          id="description"
          value={formData.media_details.description}
          onChange={handleChange}
          required
          className="mt-2 block w-full h-32 px-3 py-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
        <div className="absolute right-3 bottom-3 text-sm text-gray-500">Tell viewers about your video (type @ to mention a channel)</div>
      </div>
      <div>
        <label htmlFor="category" className="block text-lg font-medium text-gray-700">Category</label>
        <select
          name="category"
          id="category"
          value={formData.category}
          onChange={handleChange}
          required
          className="mt-2 block w-full h-12 px-3 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        >
          <option value="">Choose a category</option>
          {categories.map((category, index) => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
          <option value="Other">Other</option>
        </select>
        {formData.category === 'Other' && (
          <input
            type="text"
            name="customCategory"
            id="customCategory"
            value={formData.customCategory}
            onChange={handleChange}
            placeholder="Enter category"
            className="mt-2 block w-full h-12 px-3 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
          />
        )}
      </div>
      
      

      <div className="relative">
  <label className="block text-lg font-medium text-gray-700">Visibility</label>
  <div className="mt-2 flex items-center space-x-4">
    <div>
      <input
        type="radio"
        id="visibilityPublic"
        name="visibility"
        value="public"
        checked={formData.visibility === "public"} // Compare with string
        onChange={handleChange}
        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
      />
      <label htmlFor="visibilityPublic" className="ml-2 text-lg text-gray-700">Public</label>
    </div>
    <div>
      <input
        type="radio"
        id="visibilityUnlisted"
        name="visibility"
        value="unlisted"
        checked={formData.visibility === "unlisted"} // Compare with string
        onChange={handleChange}
        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
      />
      <label htmlFor="visibilityUnlisted" className="ml-2 text-lg text-gray-700">Unlisted</label>
    </div>
    <div>
      <input
        type="radio"
        id="visibilityPrivate"
        name="visibility"
        value="private"
        checked={formData.visibility === "private"} // Compare with string
        onChange={handleChange}
        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
      />
      <label htmlFor="visibilityPrivate" className="ml-2 text-lg text-gray-700">Private</label>
    </div>
  </div>
</div>
      <div className="relative">
        <label htmlFor="collection" className="block text-lg font-medium text-gray-700">Collection</label>
        <select
          name="collectionName"
          id="collection"
          value={formData.collectionName}
          onChange={handleChange}
          className="mt-2 block w-full h-12 px-3 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        >
          <option value="">Choose a collection</option>
          {collections.map((collection) => (
            <option key={collection.id} value={collection.id}>{collection.name}</option>
          ))}
        </select>
      </div>

      <button
        type="button"
        onClick={handleNextClick}
        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Next
      </button>
    </div>
  );
};

export default Details;