import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Load translations from backend files
  .use(LanguageDetector) // Detect user-preferred language
  .use(initReactI18next) // Integrate with React
  .init({
    supportedLngs: ['en', 'ar'], // Supported languages
    fallbackLng: 'en', // Default language
    debug: true, // Enable debug mode in development
    interpolation: {
      escapeValue: false, // React already escapes values to prevent XSS
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Translation file path
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'], // Language detection order
      caches: ['localStorage', 'cookie'], // Save detected language in localStorage and cookies
    },
    react: {
      useSuspense: true, // Use React suspense for loading translations
    },
    saveMissing: true, // Log missing translations for debugging
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      console.warn(`Missing translation for key: "${key}" in language: "${lng}"`);
    },
    keySeparator: '.', // Define key separator
    nsSeparator: ':', // Define namespace separator
  });

export default i18n;
