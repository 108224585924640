import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PlusCircle, MinusCircle, Save, CheckCircle, Trash2, Edit2, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_Django_API_URL;
const token = localStorage.getItem('authToken');
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
};

interface Question {
  id: number;  // Add this line
  type: 'multiple_choice' | 'true_false';
  question_text: string;
  options: string[];
  correct_answer: string | null;
  correct_option: string | null;
}

interface ManageQuizProps {
  ebookId: number;
  ebookTitle: string;
}
const Modal: React.FC<{ isOpen: boolean; onClose: () => void; children: React.ReactNode }> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        {children}
        <button
          onClick={onClose}
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};
const ManageQuiz: React.FC<ManageQuizProps> = ({ ebookId, ebookTitle }) => {
  const [quizTitle, setQuizTitle] = useState('');
  const [quizId, setQuizId] = useState<number | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Omit<Question, 'id'>>({
    type: 'multiple_choice',
    question_text: '',
    options: ['', ''],
    correct_answer: null,
    correct_option: null,
  });
  const [editingQuestionId, setEditingQuestionId] = useState<number | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const saveQuiz = async () => {
    if (quizId) {
      setIsModalOpen(true);
      return;
    }

    if (isSaving) {
      return;
    }

    setIsSaving(true);
    const token = localStorage.getItem('authToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  
    try {
      console.log('Sending request to:', `${BASE_URL}/api/e/quizzes/`);
      console.log('Request payload:', { title: quizTitle, ebook: ebookId });
  
      const response = await axios.post(
        `${BASE_URL}/api/e/quizzes/`,
        { title: quizTitle, ebook: ebookId },
        { headers }
      );
  
      console.log('Response:', response.data);
      setQuizId(response.data.id);
      toast.success('Quiz created successfully');
    } catch (error) {
      console.error('Error saving quiz:', error);
      if (axios.isAxiosError(error)) {
        console.error('Error response:', error.response);
        console.error('Error request:', error.request);
        console.error('Error config:', error.config);
      }
      toast.error('Failed to create quiz. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const addQuestion = async () => {
    if (!quizId) {
      toast.error('Please save the quiz first');
      return;
    }

    try {
      const questionData = {
        quiz: quizId,
        question_text: currentQuestion.question_text,
        type: currentQuestion.type,
        correct_answer: currentQuestion.type === 'true_false' ? currentQuestion.correct_answer : null,
        options: currentQuestion.type === 'multiple_choice' ? currentQuestion.options : null,
        correct_option: currentQuestion.type === 'multiple_choice' ? currentQuestion.correct_option : null,
      };

      const response = await axios.post(
        `${BASE_URL}/api/e/questions/`,
        questionData,
        { headers }
      );

      setQuestions([...questions, response.data]);
      setCurrentQuestion({
        type: 'multiple_choice',
        question_text: '',
        options: ['', ''],
        correct_answer: null,
        correct_option: null,
      });
      toast.success('Question added successfully');
    } catch (error) {
      console.error('Error adding question:', error);
      toast.error('Failed to add question. Please try again.');
    }
  };

  const handleQuizTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => setQuizTitle(e.target.value);

  const handleQuestionTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentQuestion({ ...currentQuestion, question_text: e.target.value });
  };

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...currentQuestion.options];
    newOptions[index] = value;
    setCurrentQuestion({ ...currentQuestion, options: newOptions });
  };

  const handleTypeChange = (type: 'multiple_choice' | 'true_false') => {
    setCurrentQuestion({
      ...currentQuestion,
      type,
      options: type === 'true_false' ? ['True', 'False'] : ['', ''],
      correct_answer: null,
      correct_option: null,
    });
  };

  const handleCorrectAnswerChange = (answer: string) => {
    if (currentQuestion.type === 'true_false') {
      setCurrentQuestion({ ...currentQuestion, correct_answer: answer });
    } else {
      setCurrentQuestion({ ...currentQuestion, correct_option: answer });
    }
  };

  const addOption = () => {
    setCurrentQuestion({
      ...currentQuestion,
      options: [...currentQuestion.options, '']
    });
  };

  const removeOption = (index: number) => {
    const newOptions = currentQuestion.options.filter((_, i) => i !== index);
    setCurrentQuestion({
      ...currentQuestion,
      options: newOptions,
      correct_option: currentQuestion.correct_option === currentQuestion.options[index] ? null : currentQuestion.correct_option
    });
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate('/ebooks/manage-ebook');
  };
  const deleteQuestion = async (questionId: number) => {
    try {
      await axios.delete(`${BASE_URL}/api/e/questions/${questionId}/`, { headers });
      setQuestions(questions.filter(q => q.id !== questionId));
      toast.success('Question deleted successfully');
    } catch (error) {
      console.error('Error deleting question:', error);
      toast.error('Failed to delete question. Please try again.');
    }
  };
  const startEditingQuestion = (question: Question) => {
    setEditingQuestionId(question.id);
    setCurrentQuestion({
      type: question.type,
      question_text: question.question_text,
      options: question.options || [],
      correct_answer: question.correct_answer,
      correct_option: question.correct_option,
    });
  };

  const cancelEditingQuestion = () => {
    setEditingQuestionId(null);
    setCurrentQuestion({
      type: 'multiple_choice',
      question_text: '',
      options: ['', ''],
      correct_answer: null,
      correct_option: null,
    });
  };

  const updateQuestion = async () => {
    if (!editingQuestionId || !quizId) {
      toast.error('Unable to update question. Missing question ID or quiz ID.');
      return;
    }
  
    try {
      const questionData = {
        quiz: quizId,
        question_text: currentQuestion.question_text,
        type: currentQuestion.type,
        correct_answer: currentQuestion.type === 'true_false' ? currentQuestion.correct_answer : null,
        options: currentQuestion.type === 'multiple_choice' ? currentQuestion.options : null,
        correct_option: currentQuestion.type === 'multiple_choice' ? currentQuestion.correct_option : null,
      };
  
      console.log('Updating question with data:', questionData);
  
      const response = await axios.put<Question>(
        `${BASE_URL}/api/e/questions/${editingQuestionId}/`,
        questionData,
        { headers }
      );
  
      console.log('Update response:', response.data);
  
      setQuestions(questions.map(q => q.id === editingQuestionId ? response.data : q));
      cancelEditingQuestion();
      toast.success('Question updated successfully');
    } catch (error) {
      console.error('Error updating question:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        if (error.response.status === 400) {
          // Handle validation errors
          const validationErrors = error.response.data;
          let errorMessage = 'Validation error(s):';
          if (typeof validationErrors === 'object') {
            for (const field in validationErrors) {
              errorMessage += `\n${field}: ${validationErrors[field].join(', ')}`;
            }
          } else if (Array.isArray(validationErrors)) {
            errorMessage += '\n' + validationErrors.join('\n');
          } else {
            errorMessage += '\n' + String(validationErrors);
          }
          toast.error(errorMessage);
        } else {
          toast.error(`Failed to update question: ${error.response.data.detail || 'Unknown error'}`);
        }
      } else {
        toast.error('Failed to update question. Please try again.');
      }
    }
  };
  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8 bg-gray-100">
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <h2 className="text-2xl font-bold mb-4">Quiz Already Saved</h2>
        <p>Your quiz has been saved. You will now be redirected to the Manage Ebooks page.</p>
      </Modal>
  
      <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 className="text-3xl font-bold text-center mb-6 text-indigo-600">Create Quiz for {ebookTitle}</h2>
        <input
          type="text"
          placeholder="Enter quiz title"
          value={quizTitle}
          onChange={handleQuizTitleChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button
          onClick={saveQuiz}
          disabled={isSaving}
          className={`w-full mt-4 bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center ${isSaving ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <Save className="mr-2" size={18} />
          {quizId ? 'Quiz Saved' : (isSaving ? 'Saving...' : 'Save Quiz')}
        </button>
      </div>
  
      {quizId && (
        <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4">
          <h3 className="text-2xl font-semibold mb-6 text-indigo-600">
            {editingQuestionId ? 'Edit Question' : 'Add New Question'}
          </h3>
          <div className="space-y-6">
  <div className="flex space-x-4">
    <label className="inline-flex items-center">
      <input
        type="radio"
        className="form-radio text-indigo-600"
        name="questionType"
        value="multiple_choice"
        checked={currentQuestion.type === 'multiple_choice'}
        onChange={() => handleTypeChange('multiple_choice')}
      />
      <span className="ml-2">Multiple Choice</span>
    </label>
    <label className="inline-flex items-center">
      <input
        type="radio"
        className="form-radio text-indigo-600"
        name="questionType"
        value="true_false"
        checked={currentQuestion.type === 'true_false'}
        onChange={() => handleTypeChange('true_false')}
      />
      <span className="ml-2">True/False</span>
    </label>
  </div>

  <input
    type="text"
    placeholder="Enter your question"
    value={currentQuestion.question_text}
    onChange={handleQuestionTextChange}
    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
  />
  
  {currentQuestion.type === 'multiple_choice' && (
    <>
      {currentQuestion.options.map((option, index) => (
        <div key={index} className="flex items-center space-x-2">
          <input
            type="text"
            placeholder={`Option ${index + 1}`}
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            className="flex-grow px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <button
            onClick={() => handleCorrectAnswerChange(option)}
            className={`p-2 rounded-full ${currentQuestion.correct_option === option ? 'bg-green-500 text-white' : 'bg-gray-200'}`}
          >
            <CheckCircle size={18} />
          </button>
          <button
            onClick={() => removeOption(index)}
            className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300 ease-in-out"
          >
            <MinusCircle size={18} />
          </button>
        </div>
      ))}
      <button
        onClick={addOption}
        className="mt-2 flex items-center text-indigo-600 hover:text-indigo-800"
      >
        <PlusCircle size={18} className="mr-1" /> Add Option
      </button>
    </>
  )}
  
  {currentQuestion.type === 'true_false' && (
    <div className="space-y-2">
      {['True', 'False'].map((option) => (
        <label key={option} className="flex items-center space-x-2">
          <input
            type="radio"
            className="form-radio text-indigo-600"
            name="correctAnswer"
            value={option}
            checked={currentQuestion.correct_answer === option}
            onChange={() => handleCorrectAnswerChange(option)}
          />
          <span>{option}</span>
        </label>
      ))}
    </div>
  )}
            <button
              onClick={editingQuestionId ? updateQuestion : addQuestion}
              className={`w-full ${editingQuestionId ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-green-500 hover:bg-green-600'} text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center mt-4`}
            >
              {editingQuestionId ? <Edit2 className="mr-2" size={18} /> : <PlusCircle className="mr-2" size={18} />}
              {editingQuestionId ? 'Update Question' : 'Add Question'}
            </button>
  
            {editingQuestionId && (
              <button
                onClick={cancelEditingQuestion}
                className="w-full mt-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center"
              >
                <X className="mr-2" size={18} />
                Cancel Editing
              </button>
            )}
          </div>
        </div>
      )}
  
  {questions.length > 0 && (
      <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4">
        <h3 className="text-2xl font-semibold mb-6 text-indigo-600">Added Questions</h3>
        <div className="space-y-4">
          {questions.map((q) => (
            <div key={q.id} className="flex justify-between items-center p-4 bg-gray-50 rounded-lg shadow hover:shadow-md transition duration-300">
              <span className="font-medium flex-grow mr-4">{q.question_text}</span>
              <span className="text-sm text-gray-500 mr-4">{q.type}</span>
              <div className="flex space-x-2">
                <button
                  onClick={() => startEditingQuestion(q)}
                  className="p-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 transition duration-300 ease-in-out"
                  title="Edit question"
                >
                  <Edit2 size={18} />
                </button>
                <button
                  onClick={() => deleteQuestion(q.id)}
                  className="p-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300 ease-in-out"
                  title="Delete question"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
    </div>
  );
};

export default ManageQuiz;