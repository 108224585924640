import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import '@react-pdf-viewer/core/lib/styles/index.css';

// Set the workerSrc for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const Reader: React.FC = () => {
    const { id } = useParams<{ id: string }>();  // Get the id from the URL params
    const navigate = useNavigate();
    const location = useLocation();
    const [pdfUrl, setPdfUrl] = useState<string | undefined>(location.state?.pdfUrl);  // Get pdfUrl from state
    const [pageNumber, setPageNumber] = useState(1);  // Default page number is 1
    const [numPages, setNumPages] = useState<number | null>(null);

    useEffect(() => {
        // Load the last-read page from localStorage if it exists
        const savedPage = localStorage.getItem(`book-${id}-page`);
        if (savedPage) {
            setPageNumber(parseInt(savedPage, 10));  // Start from the last-read page
        }

        // If pdfUrl is undefined, we might want to load it from the backend using the id.
        if (!pdfUrl && id) {
            console.error("PDF URL is missing.");
        }
        // replace http with https
        if (pdfUrl) {
            setPdfUrl(pdfUrl.replace('http://', 'https://'));
        }
    }, [id, pdfUrl]);

    // Save the current page to localStorage when the user navigates pages
    const handlePageChange = (direction: 'prev' | 'next') => {
        if (direction === 'prev' && pageNumber > 1) {
            setPageNumber((prevPage) => {
                const newPage = prevPage - 1;
                localStorage.setItem(`book-${id}-page`, String(newPage));  // Save page number
                return newPage;
            });
        } else if (direction === 'next' && numPages && pageNumber < numPages) {
            setPageNumber((prevPage) => {
                const newPage = prevPage + 1;
                localStorage.setItem(`book-${id}-page`, String(newPage));  // Save page number
                return newPage;
            });
        } else if (direction === 'next' && numPages && pageNumber === numPages) {
            navigate(`/ebooks/quiz/${id}`);  // Navigate to the quiz page for the specific book
        }
    };

    return (
        <div className="min-h-screen flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl">
                <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-semibold">
                        Page {pageNumber} of {numPages}
                    </span>
                </div>

                <div className="relative overflow-hidden">
                    {pdfUrl ? (
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        >
                            <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                        </Document>
                    ) : (
                        <div>Loading PDF...</div>
                    )}
                    {/* Overlay to disable copy-paste */}
                    <div className="absolute inset-0 bg-transparent select-none"></div>
                </div>

                <div className="flex justify-between items-center mt-4">
                    <button
                        onClick={() => handlePageChange('prev')}
                        disabled={pageNumber <= 1}
                        className="bg-gray-200 text-gray-600 font-bold py-2 px-4 rounded disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => handlePageChange('next')}
                        className="bg-gray-200 text-gray-600 font-bold py-2 px-4 rounded disabled:opacity-50"
                    >
                        {pageNumber === numPages ? 'Take a Quiz' : 'Next'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Reader;
