import React from 'react';

const Reading: React.FC = () => {
  console.log("Reading component rendered");
  return (
    <div style={{ padding: '16px', overflow: 'auto' }}>
      <h1 style={{ color: 'black' }}>To Reading Page Content</h1>
      <p>If you see this, the component is rendering correctly this is the end of the sentence.</p>
    </div>
  );
};

export default Reading;
