import { IconButton } from "@mui/material";
import { FaShare } from "react-icons/fa";

interface ShareProps {
  url: string;
}

export default function Share({ url }: ShareProps) {
  return (
    <IconButton
      aria-label="share"
      onClick={() => {
        navigator.clipboard.writeText(url);
        alert("URL copied to clipboard");
      }}
    >
      <FaShare />
    </IconButton>
  );
}
