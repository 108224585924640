import React from 'react';
import { useParams } from 'react-router-dom';
import { usePlaylists } from './playlistContext';
import { VideoSimple as Video } from '../types/domain';

const IndividualPlaylist: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const { playlists, deleteVideoFromPlaylist } = usePlaylists();

  const playlist = playlists.find((p) => p.name === name);

  if (!playlist) {
    return <div>Playlist not found</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 text-primary">{playlist.name}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {playlist.videos.map((video) => (
          <div key={video.id.videoId} className="relative p-4 bg-white rounded-lg shadow-lg hover:shadow-2xl transform transition duration-300 hover:scale-105">
            <img
              src={video.snippet?.thumbnails?.default?.url || 'default-thumbnail-url.jpg'}
              alt={video.snippet?.title || 'Video thumbnail'}
              className="w-full h-32 object-cover rounded-t-lg"
            />
            <div className="p-2">
              <h3 className="text-lg font-semibold text-primary">{video.snippet?.title || 'Untitled'}</h3>
              <p className="text-sm text-gray-600">{video.snippet?.channelTitle || 'Unknown Channel'}</p>
            </div>
            <button
              onClick={() => deleteVideoFromPlaylist(video.id.videoId, playlist.name)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndividualPlaylist;
