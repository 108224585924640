import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Book } from '../../types/ebooks/ebooks';
import { addBookToReadLater, removeBookFromReadLater } from '../../services/ebooks'; // Import backend services

interface BookCardProps extends Book {
  addToRead: (book: Book) => void;
  removeFromToRead: (bookId: number) => void; // Add this prop to remove the book from the list
}

const BookCard: React.FC<BookCardProps> = ({
  id,
  title,
  author,
  year,
  cover,
  description,
  date,
  genre,
  pdfUrl,
  addToRead,
  removeFromToRead, // Destructure this prop
}) => {
  const [hovered, setHovered] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isAddedToReadLater, setIsAddedToReadLater] = useState(false); // Track if added to read later
  const navigate = useNavigate();

  const handleDetails = () => {
    navigate(`/ebooks/${id}`); // Navigate to the book details page
  };

  const handleAddToRead = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();

    try {
      // Make a POST request to add the ebook to the "Read Later" list
      await addBookToReadLater(id);
      addToRead({ id, title, author, year, cover, description, date, genre, pdfUrl });
      setIsAddedToReadLater(true); // Mark as added to read later
      setDropdownVisible(false); // Close dropdown after selection
    } catch (error) {
      console.error('Error adding to read later:', error);
    }
  };

  const handleRemoveFromReadLater = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();

    try {
      // Make a DELETE request to remove the ebook from the "Read Later" list
      await removeBookFromReadLater(id);
      setIsAddedToReadLater(false); // Mark as removed from read later
      removeFromToRead(id); // Immediately remove the book from the UI
      setDropdownVisible(false); // Close dropdown after selection
    } catch (error) {
      console.error('Error removing from read later:', error);
    }
  };

  const handleContinueReading = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevents the parent click handler from triggering
    navigate(`/ebooks/read/${id}`, { state: { pdfUrl } }); // Pass the pdfUrl to the reader
  };

  const handleDropdownToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // Prevents triggering the onClick for the parent div
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div
      className="relative bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleDetails} // When clicked, navigate to the details page
    >
      <div className="relative">
        <img src={cover} alt={title} className="w-full h-80 object-cover" />
        <div
          className={`absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-0 ${
            hovered ? 'opacity-80' : ''
          } transition-opacity`}
        />
        <div
          className={`absolute top-2 right-2 transition-opacity ${hovered ? 'opacity-100' : 'opacity-0'}`}
        >
          <div className="bg-white border rounded-full flex items-center shadow-md">
            <button className="p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 ${isAddedToReadLater ? 'fill-current text-blue-600' : 'text-black-400'}`} // Dynamically set the fill color
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v18l7-5 7 5V3z" />
              </svg>
            </button>
            <div className="border-l h-6"></div>
            <button onClick={handleDropdownToggle} className="p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
          {dropdownVisible && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-10">
              <div className="px-4 py-2 text-gray-700 font-bold">Set As:</div>
              <a
                href="#"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                onClick={handleAddToRead}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v18l7-5 7 5V3z" />
                </svg>
                To Read
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m4-4h-4m2 8H9m7 4H9" />
                </svg>
                Reading
              </a>
              <a
                href="#"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                onClick={handleRemoveFromReadLater}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4" />
                </svg>
                Read
              </a>
            </div>
          )}
        </div>
        <div
          className={`absolute bottom-0 left-0 w-full text-center p-2 ${
            hovered ? 'block' : 'hidden'
          }`}
        >
          <button
            className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
            onClick={handleContinueReading}
          >
            Read Now
          </button>
        </div>
      </div>
      <div className="p-4">
        <h2 className="text-lg font-semibold">{title}</h2>
        <p className="text-gray-700">{author}</p>
        <p className="text-gray-500 text-sm">{year}</p>
        <p className="text-gray-600 mt-2">{description}</p>
        <p className="text-gray-500 text-sm">{date}</p>
      </div>
    </div>
  );
};

export default BookCard;
