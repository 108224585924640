import React, { useState } from 'react';
import { getInvitationDetails, acceptInvitation } from '../../services/channelServices';
interface AcceptInvitationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvitationAccepted: () => void;  // This line is new
}

const AcceptInvitationModal: React.FC<AcceptInvitationModalProps> = ({ isOpen, onClose,onInvitationAccepted }) => {
  const [invitationToken, setInvitationToken] = useState('');
  const [invitationDetails, setInvitationDetails] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchInvitationDetails = async () => {
    if (!invitationToken) {
      setError('Please enter an invitation token.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const details = await getInvitationDetails(invitationToken);
      setInvitationDetails(details);
      console.log("invite",invitationDetails)
    } catch (err) {
      console.error('Error fetching invitation details:', err);
      setError('Failed to fetch invitation details. Please check the token and try again.');
    } finally {
      setIsLoading(false);
    }
  };


  const handleAcceptInvitation = async () => {
    if (!invitationToken) {
      setError('Please enter an invitation token.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await acceptInvitation(invitationToken);
      onInvitationAccepted();
      onClose();
    } catch (err) {
      console.error('Error accepting invitation:', err);
      setError('Failed to accept invitation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Accept Invitation</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Invitation Token
          </label>
          <input
            type="text"
            className="w-full p-2 border rounded-md"
            placeholder="Enter invitation token"
            value={invitationToken}
            onChange={(e) => setInvitationToken(e.target.value)}
          />
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {isLoading && <p className="text-blue-500 mb-4">Loading...</p>}
        {invitationDetails && (
          <div className="mb-4 p-4 bg-gray-100 rounded-md">
            <p><strong>Channel:</strong> {invitationDetails.channel_name}</p>
            <p><strong>Role:</strong> {invitationDetails.role_display}</p>
          </div>
        )}
        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-gray-200 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          {!invitationDetails ? (
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded-md"
              onClick={handleFetchInvitationDetails}
              disabled={isLoading}
            >
              Check Invitation
            </button>
          ) : (
            <button
              className="px-4 py-2 bg-green-500 text-white rounded-md"
              onClick={handleAcceptInvitation}
              disabled={isLoading}
            >
              Accept Invitation
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceptInvitationModal;