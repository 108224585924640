import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FilterState } from '../../types/forms/video';
import VideoFilter from '../filter/VideoFilter';
import { mostViewedVideosData } from '../mock/AnalyticsData';

const getYoutubeThumbnail = (url: string) => {
  console.log("url", url);
  const videoId = url.split('v=')[1];
  const ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition !== -1) {
    return `https://img.youtube.com/vi/${videoId.substring(0, ampersandPosition)}/0.jpg`;
  }
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

const MostViewedVideos: React.FC = () => {
  const [activeFilters, setActiveFilters] = useState<FilterState>({
    type: 'all',
    minViews: 0,
    maxViews: 1000000,
    category: 'all',
  });

  const handleFilterChange = (key: string, value: any) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const filterVideos = (videos: any[]) => {
    return videos.filter((video) => {
      const matchesType = activeFilters.type === 'all' || video.mediaFormat.toLowerCase() === activeFilters.type.toLowerCase();
      const matchesCategory = activeFilters.category === 'all' || video.category.toLowerCase() === activeFilters.category.toLowerCase();
      const matchesViews = video.views >= activeFilters.minViews && video.views <= activeFilters.maxViews;

      return matchesType && matchesCategory && matchesViews;
    });
  };

  const filteredVideos = filterVideos(mostViewedVideosData);

  return (
    <div className="container mx-auto p-4">
      <VideoFilter filters={activeFilters} onFilterChange={handleFilterChange} />

      <div className="overflow-x-auto bg-white shadow-lg rounded-2xl">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Video</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Views</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Media Type</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Likes</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interview Date</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredVideos.map((video) => (
              <tr key={video.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <Link to={`/watch/${video.id}`} target="_blank" rel="noopener noreferrer">
                    <img src={getYoutubeThumbnail(video.url)} alt={video.title} className="w-16 h-16 mr-4" />
                  </Link>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">{video.title}</div>
                  <div className="text-sm text-gray-500">{video.description}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.views}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.mediaFormat}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.likes}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.category}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{video.interviewDate}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MostViewedVideos;
