// // src/services/type.ts
// import axios from 'axios';

// export const searchType = async (query: string, mediaType: string) => {
//   try {
//     const url = `/api/m/user-activity/search?q=${query}&type=${mediaType}`;
//     const response = await axios.get(url);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching search results:', error);
//     throw error; 
//   }
// };

const BASE_URL = process.env.REACT_APP_Django_API_URL;

export const searchType = async (query: string, mediaType: string) => {
  const url = `${BASE_URL}/api/m/user-activity/search?q=${query}&type=${mediaType}`;
  const authToken = localStorage.getItem("authToken"); // Ensure this matches the storage key in use

  try {
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`, // Add Bearer token
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch search results: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;
  }
};




