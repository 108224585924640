import axios from 'axios';

const BASE_URL = process.env.REACT_APP_Django_API_URL;

export const authenticateUser = async (username: string, password: string): Promise<any> => {
  try {
    const response = await axios.post(`${BASE_URL}/api/u/login/`, { username, password }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status === 200 && response.data) {
      const { message, qr_code_url, access } = response.data;
      

      localStorage.setItem('authToken', access);

      if (message === '2FA required') {
        return { status: '2FA required', qrCodeUrl: qr_code_url };
      }

      return { status: 'success' };
    } else {
      return { status: 'error', message: response.data.message || 'Invalid credentials' };
    }
  } catch (error) {
    console.error('Error during authentication:', error);
    return { status: 'error', message: 'An error occurred during authentication' };
  }
};

export const verify2FA = async (code: string): Promise<any> => {
  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.post(`${BASE_URL}/api/u/verify-2fa/`, { token: code }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 200 && response.data) {
      const { access, refresh } = response.data;
      localStorage.setItem('authToken', access);
      localStorage.setItem('refreshToken', refresh);
      return { status: 'success' };
    } else {
      return { status: 'error', message: response.data.message || 'Invalid 2FA code' };
    }
  } catch (error) {
    console.error('Error during 2FA verification:', error);
    return { status: 'error', message: 'An error occurred during 2FA verification' };
  }
};

export const registerUser = async (formPayload: Record<string, any>) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/u/register/`, formPayload);
    return { success: true, data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { success: false, error: error.response?.data.message || 'An error occurred during registration.' };
    }
    return { success: false, error: 'An unknown error occurred' };
  }
};





export const isAuthenticated = async (): Promise<boolean> => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    console.error('No auth token found in localStorage.');
    return false;
  }
  console.log('token:', token);

  try {
    const response = await axios.post(
      `${BASE_URL}/api/u/verify/`,
      {}, // Optional: You can pass an empty object as data
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.status === 200;
  } catch (error:any) {
    console.error('Error verifying authentication:', error.response ? error.response.data : error.message);
    return false;
  }
};

