import React, { useEffect, useState } from 'react';
import BookCard from '../../components/ebooks/BookCard';
import { Book } from '../../types/ebooks/ebooks';
import { fetchReadLaterBooks } from '../../services/ebooks'; // Import the service function

interface ToReadProps {
  books: Book[];  // Add the books prop to the interface
  addToRead: (book: Book) => void;
}

const ToRead: React.FC<ToReadProps> = ({ addToRead }) => {
  const [books, setBooks] = useState<Book[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Function to remove a book from the list
  const removeFromToRead = (bookId: number) => {
    setBooks((prevBooks) => prevBooks.filter((book) => book.id !== bookId)); // Filter out the removed book
  };

  // Fetch the "Read Later" books from the backend
  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const readLaterBooks = await fetchReadLaterBooks();
        setBooks(readLaterBooks);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch read later books', err);
        setError('Failed to fetch books.');
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ padding: '16px', overflow: 'auto' }}>
      <h1 className="text-3xl font-bold mb-6">To Read Page Content</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {books.length > 0 ? (
          books.map((book, index) => (
            <BookCard
              key={index}
              id={book.id}
              title={book.title}
              author={book.author}
              year={book.year}
              cover={book.cover}
              description={book.description}
              pdfUrl={book.pdfUrl}
              date={book.date}
              genre={book.genre}
              addToRead={addToRead}
              removeFromToRead={removeFromToRead} // Pass the function to remove the book
            />
          ))
        ) : (
          <p>No books added to read later yet.</p>
        )}
      </div>
    </div>
  );
};

export default ToRead;
