import React from 'react';
import SummaryCard from '../components/analytics/SummaryCard';
import VideoViewsChart from '../components/analytics/VideoViewsChart';
import MostViewedVideos from '../components/analytics/MostViewedVideos';
import AverageWatchTimeChart from '../components/analytics/AverageWatchTimeChart';
import TotalViewsPerMonth from '../components/analytics/TotalViewsPerMonth';

const Analytics: React.FC = () => {
  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-4xl font-bold mb-6 text-gray-800">Dashboard</h1>

<div className="mb-8">
  <h2 className="text-2xl font-semibold mb-4 text-gray-800">Account Summary</h2>
  <SummaryCard title="Total Videos" value={52342} increment={1479} />
</div>

<div className="mb-8">
  <h2 className="text-2xl font-semibold mb-4 text-gray-800">Video Views</h2>
  <VideoViewsChart />
</div>

<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
  <div>
    <h2 className="text-2xl font-semibold mb-4 text-gray-800">Total Views Per Month</h2>
    <TotalViewsPerMonth /> 
  </div> 
   <div>
    <h2 className="text-2xl font-semibold mb-4 text-gray-800">Average Watch Time Per Month</h2>
    <AverageWatchTimeChart /> 
  </div>
</div>

<div className="mb-8">
  <h2 className="text-2xl font-semibold mb-4 text-gray-800">Most Viewed Videos</h2>
  <MostViewedVideos />
</div>

    </div>
  );
};

export default Analytics;
