// components/ebooks/BookDetails.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BookDetail } from '../../types/ebooks/ebooks';
import { fetchBook, fetchComments, postComment } from '../../services/ebooks';
import { useNavigate } from 'react-router-dom';
const BookDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [book, setBook] = useState<BookDetail | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [review, setReview] = useState<string>('');
  const [comments, setComments] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    const loadBook = async () => {
      if (id) {
        const bookData = await fetchBook(parseInt(id, 10));
        setBook(bookData);
        const commentsData = await fetchComments(parseInt(id, 10));
        setComments(commentsData);
        setLoading(false);
      }
    };

    loadBook();
  }, [id]);

  const handleReviewSubmit = async () => {
    if (book && review) {
      try {
        const newComment = await postComment(book.id, review, null);
        setComments([...comments, newComment]);
        resetReviewForm();
      } catch (error) {
        console.error('Error submitting review:', error);
      }
    }
  };

  const resetReviewForm = () => {
    setReview('');
  };
  const handleContinueReading = () => {
    // Load the last-read page from localStorage
    const lastPage = localStorage.getItem(`book-${id}-page`);
    const pageNumber = lastPage ? parseInt(lastPage, 10) : 1;
  
    // Pass the page number and PDF URL to the Reader component
    navigate(`/ebooks/read/${id}`, { state: { pdfUrl: book?.pdfUrl, pageNumber } });
  };
  


  const handleTakeQuiz = () => {
    navigate(`/ebooks/quiz/${id}`);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!book) {
    return <NotFound />;
  }

  return (
    <div className="p-6">
      <BookHeader book={book} onContinueReading={handleContinueReading} onTakeQuiz={handleTakeQuiz} />

      <ReviewsSection comments={comments} />
      <ReviewForm review={review} setReview={setReview} onSubmit={handleReviewSubmit} />
    </div>
  );
};

// Define LoadingSpinner within BookDetails
const LoadingSpinner: React.FC = () => (
  <div className="flex justify-center items-center h-full">
    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

// Define NotFound within BookDetails
const NotFound: React.FC = () => (
  <div className="text-center p-6">
    <h1 className="text-2xl font-bold">404 - Book Not Found</h1>
    <p className="text-gray-600">The book you are looking for does not exist.</p>
  </div>
);

/// Define BookHeader within BookDetails
const BookHeader: React.FC<{ book: BookDetail, onContinueReading: () => void, onTakeQuiz: () => void }> = ({ book, onContinueReading, onTakeQuiz }) => (
  <div className="flex flex-col md:flex-row">
    <img src={book.cover} alt={book.title} className="w-full md:w-1/3 h-auto object-cover mb-4 md:mb-0 rounded-2xl" />
    <div className="md:ml-6">
      <h1 className="text-4xl font-bold mb-2">{book.title}</h1>
      <p className="text-lg text-gray-700 mb-1"><strong>Author:</strong> {book.author}</p>
      <p className="text-lg text-gray-700 mb-1"><strong>Genre:</strong> {book.genre}</p>
      <p className="text-lg text-gray-700 mb-1"><strong>Publication Date:</strong> {book.year}</p>
      <p className="text-gray-600 mt-4 mb-4"><strong>Synopsis:</strong> {book.longSynopsis}</p>
      <div className="mb-4">
        <button className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-md mr-2" onClick={onContinueReading}>
          Continue Reading
        </button>
        <button className="bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded-md" onClick={onTakeQuiz}>
          Take a Quiz
        </button>
      </div>
    </div>
  </div>
);

// Reviews Section (Comments Display)
const ReviewsSection: React.FC<{ comments: any[] }> = ({ comments }) => (
  <div className="mt-6">
    <h2 className="text-2xl font-bold mb-4">Reviews</h2>
    <ul className="space-y-4">
      {comments.map((comment) => (
        <li key={comment.id} className="flex items-start space-x-3 p-4 bg-white shadow-md rounded-lg">
          <div className="flex-shrink-0">
            <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-red-500 text-white">
              {comment.body.charAt(0).toUpperCase()}
            </span>
          </div>
          <div>
            <div className="text-sm font-medium text-gray-900">{comment.username}</div> {/* Display username */}
            <div className="text-xs text-gray-500">{new Date(comment.created).toLocaleDateString()}</div>
            <div className="mt-1 text-sm text-gray-700">{comment.body}</div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

// Review Form
const ReviewForm: React.FC<{ review: string; setReview: React.Dispatch<React.SetStateAction<string>>; onSubmit: () => void }> = ({ review, setReview, onSubmit }) => (
  <div className="mt-6">
    <h2 className="text-2xl font-bold mb-4">Leave a Review</h2>
    <textarea
      className="w-full border border-gray-300 p-2 rounded-md mb-2"
      placeholder="Write your review here"
      value={review}
      onChange={(e) => setReview(e.target.value)}
    />
    <button
      className="bg-green-600 text-white font-semibold py-2 px-4 rounded-md"
      onClick={onSubmit}
    >
      Submit Review
    </button>
  </div>
);

export default BookDetails;
