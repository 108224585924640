const BASE_URL = process.env.REACT_APP_Django_API_URL;

export const fetchLanguages = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/m/language/`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`
      }
    });
    if (!response.ok) {
      throw new Error('Failed to fetch languages');
    }
    const data = await response.json();
    // Map the data to the desired format for the language options
    console.log("languages",data)
    return data.results.map((language: { id: number; language: string }) => ({
      value: language.id, // or language.language if you prefer the language code
      label: language.language
    }));
  } catch (error) {
    console.error('Error fetching languages:', error);
    throw error;
  }
};
