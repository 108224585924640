import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { VideoSimple as Video } from '../types/domain';

interface WatchLaterContextProps {
  watchLaterVideos: Video[];
  addToWatchLater: (video: Video) => void;
  removeFromWatchLater: (videoId: string) => void;
}

const WatchLaterContext = createContext<WatchLaterContextProps>({
  watchLaterVideos: [],
  addToWatchLater: () => {},
  removeFromWatchLater: () => {},
});

interface WatchLaterProviderProps {
  children: ReactNode;
}

export const WatchLaterProvider: React.FC<WatchLaterProviderProps> = ({ children }) => {
  const [watchLaterVideos, setWatchLaterVideos] = useState<Video[]>([]);

  useEffect(() => {
    const storedVideos = localStorage.getItem('');
    if (storedVideos) {
      setWatchLaterVideos(JSON.parse(storedVideos));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('watchLaterVideos', JSON.stringify(watchLaterVideos));
  }, [watchLaterVideos]);

  const addToWatchLater = (video: Video) => {
    setWatchLaterVideos((prevVideos) => {
      if (!prevVideos.find(v => v.id.videoId === video.id.videoId)) {
        return [...prevVideos, video];
      }
      return prevVideos;
    });
  };

  const removeFromWatchLater = (videoId: string) => {
    setWatchLaterVideos((prevVideos) => prevVideos.filter(video => video.id.videoId !== videoId));
  };

  return (
    <WatchLaterContext.Provider value={{ watchLaterVideos, addToWatchLater, removeFromWatchLater }}>
      {children}
    </WatchLaterContext.Provider>
  );
};

export const useWatchLater = () => useContext(WatchLaterContext);
